import React, { useState, useEffect } from 'react';
import NavBar from '../../share-component/navbar'
import SurveySection5 from './survey-section5'


function SurveyPart5() {    
  useEffect(() => {        
    window.scrollTo(0, 0);
  });

  return (
    <>
      <NavBar/>
      <SurveySection5/>
      
      
    </>
  );
}

export default SurveyPart5;