import React, { useState, useEffect } from 'react';
import logo from '../../logo.svg';
import NavBar from '../../share-component/navbar'
import '../../App.css';
import { Form, Icon, Button } from 'react-bulma-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGraduationCap } from '@fortawesome/free-solid-svg-icons'
import { Link} from "react-router-dom";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Swal from 'sweetalert2'
import {APIHeadQuarters} from '../../share-component/api/APIHeadQuarters';
import {OrderDetails} from '../../share-component/api/OrderDetails';
import { faKey } from '@fortawesome/free-solid-svg-icons';
import './index.scss';

function Login() {    
  
  useEffect(() => {       
    localStorage.removeItem("survey_token");
    localStorage.removeItem("survey_scores");
    localStorage.removeItem("profile_scores");
    localStorage.removeItem("is_token_info_mode");
    localStorage.removeItem("token_model");
  });

  return (
    <React.Fragment>
    
      <NavBar></NavBar>
      <div className="columns is-centered has-text-left mr-2 ml-2 login-container">
        <div className="column is-one-quarter">
          <FormLogin></FormLogin>
        </div>   
      </div>
    
    </React.Fragment>
  );
}

function FormLogin(){
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = React.useState(false);
  const [token, setToken] = React.useState('');

  const onSubmit = (e) => {
    e.preventDefault();

    setIsLoading(true);
    
    var requestUrl = '/api/public/login';
    const requestJson = {
      token: token
   };

   var orderDetails = new OrderDetails(requestUrl, requestJson, false, "POST");
   APIHeadQuarters.ExecuteStandardOrder(orderDetails).then((res)=>{
      setIsLoading(false);
    
      if(res.errorCode){
        return;
      }

      localStorage.setItem("survey_token", JSON.stringify(token));
      localStorage.setItem("survey_scores", JSON.stringify([]));
      localStorage.setItem("profile_scores", JSON.stringify([]));
      localStorage.setItem("is_token_info_mode", JSON.stringify(false));
      localStorage.setItem("token_model", JSON.stringify(res));
      

      if(res.tokenType == 2){
        navigate("/survey/typeL/consent");
      }else{
        navigate("/survey/consent");
      }
      
   });
   
    
  };

   return ( 
    <>
      <div className='has-text-centered'>
        <FontAwesomeIcon className='has-text-centered' icon={faGraduationCap} size={'xl'} bounce={true}  />
      </div>
      {/* <h1 className="is-size-6 mb-5 has-text-weight-bold has-text-centered">ผลกระทบของภาวะผู้นําเชิงจิตวิญญาณที่มีผลต่อ บรรยากาศความปลอดภัยในเชิงจิตวิทยาและผลที่มีต่อสุขภาวะของพนักงาน พฤติกรรมในการทํางาน และผลลัพธ์ของทีม</h1> */}
      {/* <div className="card">
        <div className="card-content">
          <div className="content">
          ผลกระทบของภาวะผู้นําเชิงจิตวิญญาณที่มีผลต่อ บรรยากาศความปลอดภัยในเชิงจิตวิทยาและผลที่มีต่อสุขภาวะของพนักงาน พฤติกรรมในการทํางาน และผลลัพธ์ของทีม
          </div>
        </div>
      </div> */}
      {/* <h1 className="title">ผลกระทบของภาวะผู้นําเชิงจิตวิญญาณที่มีผลต่อ บรรยากาศความปลอดภัยในเชิงจิตวิทยาและผลที่มีต่อสุขภาวะของพนักงาน พฤติกรรมในการทํางาน และผลลัพธ์ของทีม</h1> */}

      <article className="message is-dark mt-3 has-text-centered">
        <div className="message-body">
        <strong>ผลกระทบของภาวะผู้นําเชิงจิตวิญญาณที่มีผลต่อ บรรยากาศความปลอดภัยในเชิงจิตวิทยาและผลที่มีต่อสุขภาวะของพนักงาน พฤติกรรมในการทํางาน และผลลัพธ์ของทีม </strong>
        </div>
      </article>
      

      <form>
        <Form.Field>
          <Form.Label>โปรดกรอกรหัสของท่าน</Form.Label>
          <Form.Control>
            <Form.Input placeholder="passcode" name="name" value={token} onChange={event=>{              
              setToken(event.target.value);
            }}/>
            <Icon align="left">
              <FontAwesomeIcon icon={faKey} />              
            </Icon>
          </Form.Control>
        </Form.Field>        
        <Form.Control>          
          <Button fullwidth className={`mt-4 ${isLoading ? 'is-loading' : ''}`} color="black" onClick={(e)=>onSubmit(e)}>Login</Button>
        </Form.Control>      
      </form>

      <article className="message is-success is-small mt-6">
        <div className="message-body has-text-centered">
          ทุกแบบสอบถามที่ตอบคำถามโดยสมบูรณ์ จะมีมูลค่า 59 บาท บริจาคให้บัญชี ใจฟ้า อาทรประชานาท วัดพระบาทน้ำพุ 
        </div>
      </article>
      
      {/* <button className="button is-normal is-black is-fullwidth mt-4">Login</button> */}
    </>
    );
}


export default Login;
