import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import './index.scss';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import {APIHeadQuarters} from '../../share-component/api/APIHeadQuarters';
import {OrderDetails} from '../../share-component/api/OrderDetails';

function ButtonCard({sectionNumber, total, numberAnswered, nextPageURL, isSubmit=false, text="Next"}) {    
  const MySwal = withReactContent(Swal);
  const [isLoading, setIsLoading] = React.useState(false);
  const navigate = useNavigate();

  const [isTokenInfoMode, setIsTokenInfoMode] = React.useState(false);
  useEffect(() => {             
    var isTokenInfoModeValue = JSON.parse(localStorage.getItem("is_token_info_mode"));    
    if(isTokenInfoModeValue == true){
      setIsTokenInfoMode(true);
    }  
  });

  const onSubmit = (e) =>{    
    e.preventDefault();
    
    if(numberAnswered < total){
      MySwal.fire({
        title: 'ท่านยังไม่ได้ตอบคำถามบางข้อ',
        text: "โปรดตอบคำถามให้ครบทุกข้อก่อนค่ะ",
        icon: 'warning',              
        confirmButtonColor: '#3085d6',                        
      })
      return;
    }

    if(!isSubmit && nextPageURL){
      navigate(nextPageURL);
      return;
    }


    if(isSubmit == true){    

      var scoresReadOnly = JSON.parse(localStorage.getItem("survey_scores"));
      var profileScoresReadOnly = JSON.parse(localStorage.getItem("profile_scores"));
      var token = JSON.parse(localStorage.getItem("survey_token"));
      
      if(!token){
        MySwal.fire({
          title: 'ไม่พบ token',
          text: "โปรด login ใหม่",
          icon: 'warning',              
          confirmButtonColor: '#3085d6',                        
        })
        navigate("/");
        return;
      }

      if(isTokenInfoMode){
        localStorage.setItem("is_token_info_mode", JSON.stringify(false));
        navigate("/AdminMenu");
        return;
      }


      setIsLoading(true);    

      var requestUrl = '/api/public/submitScores';
      const requestJson = {
        token: token,
        scores: scoresReadOnly,
        profileScores: profileScoresReadOnly
    };

      var orderDetails = new OrderDetails(requestUrl, requestJson, false, "POST");
      APIHeadQuarters.ExecuteStandardOrder(orderDetails).then((res)=>{
          setIsLoading(false);
        
          if(res.errorCode){
            return;
          }

          MySwal.fire({
            title: 'สำเร็จ',
            text: "",
            icon: 'success',
            confirmButtonColor: '#3085d6',
          })

          navigate("/");
      });

    }
  };

  return (
    <>        
      <div className='columns is-centered pt-3 pb-3 mt-6 ButtonCard'>
        <button className={`button column is-link is-medium is-10-mobile is-3-desktop m-auto ${isLoading ? 'is-loading' : ''}`} onClick={onSubmit}>{text}</button>
      </div>      
    </>
  );
}

export default ButtonCard;