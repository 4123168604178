import React, { useState } from 'react';
import './index.scss';
import HrSurveyCard from '../../../share-component/hr';
import SurveySlider from '../survey-slider';
import { motion } from "framer-motion"
import TitleSurvey from "../../../share-component/header/titleSurvey"

function SurveyCard5({onNumberAnswered, scoresReadOnly, isVisibleLeft, isVisibleRight}) {  
  const initial = {scale: 0.9, opacity: 0.9};
  const whileInView = {scale: 1, opacity: 1};
  const animate = {};
  const whileHover = { scale: 1.007 };
  
  return (
    <>

        
        <motion.div animate={animate}
                    whileHover={whileHover}
                    initial={initial}
                    whileInView={whileInView}                    
                    >
          <TitleSurvey number={5} title={"Occupational Self-efficacy"} isVisibleLeft={isVisibleLeft} isVisibleRight={isVisibleRight} />          
        </motion.div>

        <motion.div animate={animate}
                    whileHover={whileHover}
                    initial={initial}
                    whileInView={whileInView}                    
                    >
          <div className='survey-card-container'>
              <SurveyQuestion questionNumber={1} realQuestionNumber={37} scoresReadOnly={scoresReadOnly} question={"ฉันสามารถสงบสติอารมณ์ได้เมื่อพบกับปัญหาในงาน เพราะฉันสามารถพึ่งพาความสามารถของฉันได้"} onNumberAnswered={onNumberAnswered} />
              <hr className='hr-survey-card'/>
              <SurveyQuestion questionNumber={2} realQuestionNumber={38} scoresReadOnly={scoresReadOnly} question={"เมื่อฉันต้องเผชิญหน้ากับปัญหาจากการทำงาน ฉันก็ยังสามารถหาทางออกได้หลากหลาย"} onNumberAnswered={onNumberAnswered} />
          </div>
        </motion.div>

        <hr className='hr-survey-card'/>

        <motion.div animate={animate}
                    whileHover={whileHover}
                    initial={initial}
                    whileInView={whileInView}
                      >
          <div className='survey-card-container'>
              <SurveyQuestion questionNumber={3} realQuestionNumber={39} scoresReadOnly={scoresReadOnly} question={"ฉันสามารถรับมือกับอะไรก็ตามที่เข้ามาในการทำงาน"} onNumberAnswered={onNumberAnswered} />
              <hr className='hr-survey-card'/>
              <SurveyQuestion questionNumber={4} realQuestionNumber={40} scoresReadOnly={scoresReadOnly} question={"ประสบการณ์ที่ผ่านมาของฉันได้ปูทางให้ฉันอย่างดีในอนาคตการทำงานของฉัน"} onNumberAnswered={onNumberAnswered} />
          </div>
        </motion.div>

        <hr className='hr-survey-card'/>

        <motion.div animate={animate}
                    whileHover={whileHover}
                    initial={initial}
                    whileInView={whileInView}
                    >
          <div className='survey-card-container'>
              <SurveyQuestion questionNumber={5} realQuestionNumber={41} scoresReadOnly={scoresReadOnly} question={"ฉันทำได้ตามเป้าหมายที่ฉันตั้งไว้ในการทำงาน"} onNumberAnswered={onNumberAnswered} />
              <hr className='hr-survey-card'/>
              <SurveyQuestion questionNumber={6} realQuestionNumber={42} scoresReadOnly={scoresReadOnly} question={"ฉันรู้สึกว่าฉันพร้อมรับมือกับความท้าทายต่างๆของงานที่เข้ามา"} onNumberAnswered={onNumberAnswered} />
          </div>
        </motion.div>
    </>
  );
}

function SurveyCardContainer(){

}

function SurveyQuestion({ questionNumber, question, onNumberAnswered, realQuestionNumber, scoresReadOnly}){

  var valueInit = 0;
  var isSelectInit = false;
  
  if(scoresReadOnly){
    var thisQuestionScore = scoresReadOnly[realQuestionNumber-1];
    if(thisQuestionScore && thisQuestionScore > 0){
      isSelectInit = true;
      valueInit = thisQuestionScore;      
    }
  }

  return (
    <>
      <motion.div  
      initial={{ opacity: 0, scale: 0 }}
      animate={{ opacity: 1, scale: 1 }}
      exit={{ opacity: 0 }} >
        <h1 className='is-size-4-tablet is-size-6-mobile mb-6'> {questionNumber}. {question}</h1>
        <SurveySlider onNumberAnswered={onNumberAnswered} realQuestionNumber={realQuestionNumber} valueInit={valueInit} isSelectInit={isSelectInit}/>
      </motion.div>
    </>
  )
}


export default SurveyCard5;