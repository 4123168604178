import React, { useState } from 'react';
import logo from '../../logo.svg';
import NavBar from '../../share-component/navbar'
import '../../App.css';
import { Form, Icon, Button } from 'react-bulma-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGraduationCap } from '@fortawesome/free-solid-svg-icons'
import { Link} from "react-router-dom";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Swal from 'sweetalert2'
import {APIHeadQuarters} from '../../share-component/api/APIHeadQuarters';
import {OrderDetails} from '../../share-component/api/OrderDetails';
import { faKey } from '@fortawesome/free-solid-svg-icons'

function LoginAdmin() {    
  
  localStorage.setItem("survey_scores", JSON.stringify([]));
  localStorage.setItem("profile_scores", JSON.stringify([]));
  localStorage.setItem("survey_token", "");

  return (
    <React.Fragment>
    
      <NavBar></NavBar>
      <div className="columns is-centered has-text-left mt-6 mr-2 ml-2">
        <div className="column is-one-quarter">
          <FormLogin></FormLogin>
        </div>   
      </div>
    
    </React.Fragment>
  );
}

function FormLogin(){
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = React.useState(false);
  const [token, setToken] = React.useState('');

  const onSubmit = (e) => {
    e.preventDefault();

    setIsLoading(true);
    
    var requestUrl = '/api/public/loginAdmin';
    const requestJson = {
      token: token
   };

   var orderDetails = new OrderDetails(requestUrl, requestJson, false, "POST");
   APIHeadQuarters.ExecuteStandardOrder(orderDetails).then((res)=>{
      setIsLoading(false);
    
      if(res.errorCode){
        return;
      }

      localStorage.setItem("survey_token", JSON.stringify(token));
      navigate("/adminMenu");
   });
   
    
  };

   return ( 
    <>
      <div className='has-text-centered'>
        <FontAwesomeIcon className='has-text-centered' icon={faGraduationCap} size={'xl'} bounce={true}  />
      </div>
      <h1 className="is-size-2 mb-5 has-text-weight-bold has-text-centered">Admin Login</h1>
      <form>
        <Form.Field>
          <Form.Label>Token</Form.Label>
          <Form.Control>
            <Form.Input placeholder="Username" name="name" value={token} onChange={event=>{              
              setToken(event.target.value);
            }}/>
            <Icon align="left">
              <FontAwesomeIcon icon={faKey} />              
            </Icon>
          </Form.Control>
        </Form.Field>        
        <Form.Control>          
          <Button fullwidth className={`mt-4 ${isLoading ? 'is-loading' : ''}`} color="warning" onClick={(e)=>onSubmit(e)}>Login</Button>
        </Form.Control>      
      </form>
      
      {/* <button class="button is-normal is-black is-fullwidth mt-4">Login</button> */}
    </>
    );
}


export default LoginAdmin;
