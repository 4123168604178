import Survey from './pages/survey/survey-part2'
import PagesRoute from './PagesRoute'
import Login from './pages/login'


function App() {
  return (
    <div className="App">
          <Login />
   </div>
  );
}

export default App;
