import React, { useState, useEffect } from 'react';
import NavBar from '../../share-component/navbar'
import SurveySection7 from './survey-section7'


function SurveyPart7() {    
  useEffect(() => {        
    window.scrollTo(0, 0);
  });

  return (
    <>
      <NavBar/>
      <SurveySection7/>
      
      
    </>
  );
}

export default SurveyPart7;