import React, { useState, useEffect } from 'react';
import NavBar from '../../../share-component/navbar'
import { Form, Icon, Button } from 'react-bulma-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGraduationCap } from '@fortawesome/free-solid-svg-icons'
import { useNavigate, Link } from "react-router-dom";
import axios from "axios";
import Swal from 'sweetalert2'
import { faKey } from '@fortawesome/free-solid-svg-icons'
import { LoginService } from '../../../share-component/api/services/LoginService'

function AdminMenu() {    
  const navigate = useNavigate();
  useEffect(() => {        
    LoginService.IsLogin(navigate, "/loginAdmin");
  });

  return (
    <React.Fragment>
    
      <NavBar></NavBar>      
      <hr></hr>

      <div className="columns is-centered has-text-left mr-2 ml-2 login-container">
      
          <aside className="menu">
            <p className="menu-label">
                Token
            </p>
            <ul className="menu-list">
                <li>
                    <Link to="/AddToken"><b>สร้าง Token ใหม่</b></Link>
                </li>
                <li>
                    <Link to="/AllTokenInfo"><b>ดู Token ทั้งหมด</b></Link>
                </li>
            </ul>
            <p className="menu-label">
                Survey
            </p>
            <ul className="menu-list">
                <li><Link to="/TokenInfo"><b>ดูผล Survey โดย Token</b></Link></li>
            </ul>            
        </aside>
      </div>
    
    </React.Fragment>
  );
}


export default AdminMenu;
