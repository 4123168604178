import React, { useState, useEffect } from 'react';
import NavBar from '../../share-component/navbar'
import NavBarnavbarSurveyProgress from '../../share-component/navbar-survey-progress'
import SurveySection2 from './survey-section2'


function SurveyPart2() {    

  useEffect(() => {        
    window.scrollTo(0, 0);
  });

  return (
    <>
      <NavBar/>
      <SurveySection2/>
      
      
    </>
  );
}

export default SurveyPart2;