import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleChevronLeft, faCircleChevronRight } from '@fortawesome/free-solid-svg-icons';
import "./index.scss"
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion"

function TitleSurvey({number, title, isVisibleLeft = false, isVisibleRight = false, subtitleText = null, isBoss = false}) {    
  const navigate = useNavigate();

  const initial = {scale: 1, opacity: 1};
  const whileInView = {scale: 1, opacity: 1};
  const animate = {};
  const whileHover = { scale: 1 };

  return (
    <>        
        <div className='survey-card-container has-text-centered title-container'>
            <div>              
              {isVisibleLeft && 

              <motion.div animate={animate}
                          whileHover={whileHover}              
                          whileInView={whileInView}
                          initial={initial}>
                <div className='' onClick={(e)=>{
                  e?.preventDefault();
                  var url = `/survey/part${number-1}`;
                  if(isBoss == true){ url = `/survey/typeL/part${number-1}`;}
                  navigate(url);
                }}>
                <FontAwesomeIcon icon={faCircleChevronLeft} className="arrow-left"/>
                </div>
              </motion.div>
             }
              
              {isVisibleRight && 

                  <motion.div animate={animate}
                    whileHover={whileHover}              
                    whileInView={whileInView}
                    initial={initial} >
                    <div className='' onClick={(e)=>{
                      e?.preventDefault();
                      var url = `/survey/part${number+1}`;               
                      if(isBoss == true){ url = `/survey/typeL/part${number+1}`;} 
                      navigate(url);
                    }}>
                      <FontAwesomeIcon icon={faCircleChevronRight} className="arrow-right"/>
                    </div> 
                  </motion.div>
                  }



                  <h1 className='is-size-4 is-size-6-mobile'>ส่วนที่ {number} </h1>
                </div>
            <b><span className='is-size-3 is-size-4-mobile'> {title}</span></b>
            {subtitleText != null && 
                    <p><b>{subtitleText}</b></p>
                  }
        </div>
    </>
  );
}

export default TitleSurvey;
