import React, { useState } from 'react';
import './index.scss';
import HrSurveyCard from '../../../share-component/hr';
import SurveyRadio from '../../survey/survey-radio';
import { motion } from "framer-motion"
import TitleSurvey from "../../../share-component/header/titleSurvey"

function SurveyBossCard4({onNumberAnswered, scoresReadOnly, isVisibleLeft, isVisibleRight}) {  
  const initial = {scale: 0.9, opacity: 0.9};
  const whileInView = {scale: 1, opacity: 1};
  const animate = {};
  const whileHover = { scale: 1.007 };
  
  return (
    <>
        <motion.div animate={animate}
                    whileHover={whileHover}
                    initial={initial}
                    whileInView={whileInView}                    
                    >
          <TitleSurvey number={4} title={"Improvisational Behavior"} isVisibleLeft={isVisibleLeft} isVisibleRight={isVisibleRight} isBoss={true} /> 
        </motion.div>

        <motion.div animate={animate}
                    whileHover={whileHover}
                    initial={initial}
                    whileInView={whileInView}                    
                    >
          <div className='survey-card-container'>
              <SurveyQuestion questionNumber={1} realQuestionNumber={10} scoresReadOnly={scoresReadOnly} question={"ท่านสามารถรับมือกับเหตุการณ์ที่ไม่คาดคิดได้อย่างดี"} onNumberAnswered={onNumberAnswered} />
              <hr className='hr-survey-card'/>
              <SurveyQuestion questionNumber={2} realQuestionNumber={11} scoresReadOnly={scoresReadOnly} question={"ท่านสามารถคิดแก้ปัญหาได้รวดเร็วในสถานการณ์เฉพาะหน้า"} onNumberAnswered={onNumberAnswered} />
          </div>
        </motion.div>

        <hr className='hr-survey-card'/>

        <motion.div animate={animate}
                    whileHover={whileHover}
                    initial={initial}
                    whileInView={whileInView}
                      >
          <div className='survey-card-container'>
              <SurveyQuestion questionNumber={3} realQuestionNumber={12} scoresReadOnly={scoresReadOnly} question={"ท่านสามารถหาวิธีแก้ปัญหาในเหตุการณ์ที่ไม่คาดคิดได้ดี"} onNumberAnswered={onNumberAnswered} />
              <hr className='hr-survey-card'/>
              <SurveyQuestion questionNumber={4} realQuestionNumber={13} scoresReadOnly={scoresReadOnly} question={"ท่านสามารถหาวิธีการใหม่ๆในการรับมือกับปัญหาได้ดี"} onNumberAnswered={onNumberAnswered} />
          </div>
        </motion.div>
        
        <hr className='hr-survey-card'/>

        <motion.div animate={animate}
                    whileHover={whileHover}
                    initial={initial}
                    whileInView={whileInView}
                      >
          <div className='survey-card-container'>
              <SurveyQuestion questionNumber={5} realQuestionNumber={14} scoresReadOnly={scoresReadOnly} question={"ท่านสามารถมองเห็นโอกาสและหาวิธีการใหม่ๆในการทำงานได้"} onNumberAnswered={onNumberAnswered} />
              <hr className='hr-survey-card'/>
              <SurveyQuestion questionNumber={6} realQuestionNumber={15} scoresReadOnly={scoresReadOnly} question={"ท่านกล้าเสี่ยงที่จะคิดและหาวิธีการใหม่ๆในการทำงาน"} onNumberAnswered={onNumberAnswered} />
          </div>
        </motion.div>

        <hr className='hr-survey-card'/>

        <motion.div animate={animate}
                    whileHover={whileHover}
                    initial={initial}
                    whileInView={whileInView}
                      >
          <div className='survey-card-container'>
              <SurveyQuestion questionNumber={7} realQuestionNumber={16} scoresReadOnly={scoresReadOnly} question={"ท่านสามารถสร้างสรรค์ไอเดียและกระบวนการทำงานใหม่ๆการทำงานได้เป็นอย่างดี"} onNumberAnswered={onNumberAnswered} />\
          </div>
        </motion.div>
    </>
  );
}

function SurveyCardContainer(){

}

function SurveyQuestion({ questionNumber, question, onNumberAnswered, realQuestionNumber, scoresReadOnly}){
  
  var valueInit = 0;
  var isSelectInit = false;
  
  if(scoresReadOnly){
    var thisQuestionScore = scoresReadOnly[realQuestionNumber-1];
    if(thisQuestionScore && thisQuestionScore > 0){
      isSelectInit = true;
      valueInit = thisQuestionScore;
    }
  }

  return (
    <>
      <motion.div  
      initial={{ opacity: 0, scale: 0 }}
      animate={{ opacity: 1, scale: 1 }}
      exit={{ opacity: 0 }} >
        <h1 className='is-size-4-tablet is-size-6-mobile mb-6'> {questionNumber}. {question}</h1>
        <SurveyRadio onNumberAnswered={onNumberAnswered} realQuestionNumber={realQuestionNumber} valueInit={valueInit} isSelectInit={isSelectInit}/>
      </motion.div>
    </>
  )
}


export default SurveyBossCard4;