import React, { useMemo, useEffect } from 'react';
import MaterialReactTable from 'material-react-table';
import {APIHeadQuarters} from '../../../share-component/api/APIHeadQuarters';
import {OrderDetails} from '../../../share-component/api/OrderDetails';
import { MenuItem, Box , Typography,Button} from '@material-ui/core';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import Swal from 'sweetalert2';
import { ExportToCsv } from 'export-to-csv';

function TokensInfoTable(){


  const [tableData, setTableData] = React.useState([{token: ''}]);
  const [realColumnTeamExport, setRealColumnTeamExport] = React.useState([]);
  const [realColumnTeamLeadExport, setRealColumnTeamLeadExport] = React.useState([]);  

  useEffect(() => {             
    LoadAllToken(setTableData);

    var columnTeamExport = [];
    var columnTeamLeadExport = []
    columnTeamExport = [{header: 'Token'}, {header: 'CompanyName'}, {header: 'Group'}, {header: 'TokenType'}];
    columnTeamLeadExport = [{header: 'Token'}, {header: 'CompanyName'}, {header: 'Group'}, {header: 'TokenType'}];
    const profileTotal = 6;
  
    for (let i = 1; i <= 6; i++) {
      var element = "";
      if(i == 1){
         element = "Sex"
      }
      else if(i == 2){
        element = "Age"
     }
     else if(i == 3){
       element = "WC"
      }
      else if(i == 4){
        element = "WT"
       }
       else if(i == 5){
        element = "Edu"
       }
       else if(i == 6){
        element = "Sta"
       }
     
      columnTeamExport.push({header: element});
      columnTeamLeadExport.push({header: element});
    }
  
    for (let i = 1; i <= 13; i++) {
      const element = "SL" + i;
      columnTeamExport.push({header: element});
    }
    for (let i = 1; i <= 7; i++) {
      const element = "PSC" + i;
      columnTeamExport.push({header: element});
    }
    for (let i = 1; i <= 16; i++) {
      const element = "PGI" + i;
      columnTeamExport.push({header: element});
    }
    for (let i = 1; i <= 6; i++) {
      const element = "OSE" + i;
      columnTeamExport.push({header: element});
    }
    for (let i = 1; i <= 5; i++) {
      const element = "EE" + i;
      columnTeamExport.push({header: element});
    }
    for (let i = 1; i <= 9; i++) {
      const element = "IWB" + i;
      columnTeamExport.push({header: element});
    }
    for (let i = 1; i <= 4; i++) {
      const element = "TI" + i;
      columnTeamExport.push({header: element});
    }
    for (let i = 1; i <= 5; i++) {
      const element = "TP" + i;
      columnTeamExport.push({header: element});
    }
    for (let i = 1; i <= 2; i++) {
      const element = "MV" + i;
      columnTeamExport.push({header: element});
    }
  
  
    for (let i = 1; i <= 4; i++) {
      const element = "TI" + i;
      columnTeamLeadExport.push({header: element});
    }
    for (let i = 1; i <= 5; i++) {
      const element = "TP" + i;
      columnTeamLeadExport.push({header: element});
    }
    for (let i = 1; i <= 7; i++) {
      const element = "IB" + i;
      columnTeamLeadExport.push({header: element});
    }
    for (let i = 1; i <= 15; i++) {
      const element = "MIN" + i;
      columnTeamLeadExport.push({header: element});
    }
    for (let i = 1; i <= 3; i++) {
      const element = "JA" + i;
      columnTeamLeadExport.push({header: element});
    }
    for (let i = 1; i <= 5; i++) {
      const element = "FOS" + i;
      columnTeamLeadExport.push({header: element});
    }
  
    setRealColumnTeamExport(columnTeamExport)
    setRealColumnTeamLeadExport(columnTeamLeadExport)
  
    
  }, []);

  //should be memoized or stable
  const columns = useMemo(
    () => [
      {
        accessorKey: 'token',
        header: 'Token'
      },
      {
        accessorKey: 'companyName',
        header: 'CompanyName'
      },
      {
        accessorKey: 'group',
        header: 'Group'
      },
      {
        accessorKey: 'tokenTypeName',
        header: 'TokenType'
      },
      // {
      //   accessorKey: 'profileAnswers',
      //   header: 'ProfileAnswers'
      // },
      // {
      //   accessorKey: 'surveyAnswers',
      //   header: 'SurveyAnswers'
      // },
      {
        accessorKey:'tokenGroupRef',
        header: 'TokenGroupRef'
      },
      // {
      //   accessorKey:'isGroupRefTemplate',
      //   header: 'IsGroupRefTemplate'
      // },
      // {
      //   accessorKey:'createdDate',
      //   header: 'CreatedDate'
      // },
    ],
    [],
  );

  const columnsDetail = useMemo(
    () => [
      {
        accessorKey: 'number',
        header: 'QuestionNumber'
      },
      {
        accessorKey: 'answer',
        header: 'Answers'
      },
    ],
    [],
  );


  function handleExportRows(rows){
    
    var dataToExport = [];

      var arr = [];
      var isTeamLead = false;

      if(rows.length > 0){
        if(rows[0].original.tokenType == 2){
          isTeamLead = true;
        }
      }

      rows.forEach(r => {
        var row = r.original;
        

        var data = {};
        data.token = row.token;
        data.companyName = row.companyName;
        data.group = row.group;
        data.tokenTypeName = row.tokenTypeName;

        data.profileAnswers = [];
        for (let i = 1; i <= 6; i++) {          
          if(row.profileAnswers != null && row.profileAnswers[i-1] != null){
             data.profileAnswers.push(row.profileAnswers[i-1]);
          }else{
            data.profileAnswers.push('-');
          }          
        }
      
        data.surveyAnswers = [];
        for (let i = 1; i <= 67; i++) {
          if(row.surveyAnswers != null && row.surveyAnswers[i-1] != null){
            data.surveyAnswers.push(row.surveyAnswers[i-1]);
         }else{
           data.surveyAnswers.push('-');
         }         
        }

        arr.push(data);
      });      
    
    // console.log(arr);
    var columnToUsed = realColumnTeamExport;
    if(isTeamLead){
      columnToUsed = realColumnTeamLeadExport;
    }

    const csvOptions = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalSeparator: '.',
      showLabels: true,
      useBom: true,
      useKeysAsHeaders: false,
      headers: columnToUsed.map((c) => c.header),
    };
    const csvExporter = new ExportToCsv(csvOptions);
    csvExporter.generateCsv(arr);
  }

  return <>
         
        <MaterialReactTable columns={columns} data={tableData} 
            enableRowNumbers 
            enableRowActions
            enableRowSelection
            renderTopToolbarCustomActions={({ table }) => (
              <Box
                sx={{ display: 'flex', gap: '1rem', p: '0.5rem', flexWrap: 'wrap' }}
                >
                <Button
                  color="primary"
                  onClick={()=>{
                    handleExportRows(table.getSelectedRowModel().rows)
                  }}
                  disabled={!table.getIsSomeRowsSelected() && !table.getIsAllRowsSelected()}
                  startIcon={<FileDownloadIcon />}
                  variant="contained"
                  >
                  Export Selected Rows
                </Button>
              </Box>
            )}
            renderRowActionMenuItems={(rowObject, index) => {
              // console.info(row)              

              return [              
              <MenuItem onClick={(e) => {
             
                // console.info(rowObject.row)
                // console.log(rowObject.row.original.token);                
                var selectedToken = rowObject.row.original.token;
                DeleteToken(selectedToken, setTableData);
                }
              }>Delete</MenuItem>
              ]
            }
            }

            muiExpandButtonProps={({ row }) => ({
              sx: {
                display: row.original.profileAnswers != undefined ? 'flex' : 'none',
              },
            })}


              renderDetailPanel={({ row }) => {
                
              if(row.original.profileAnswers != undefined && row.original.profileAnswers.length > 0){
               
                var tempProfile = [];
                var i = 1;
                row.original.profileAnswers.forEach(element => {

                  tempProfile.push({
                    number: i,
                    answer: element 
                  });
                  i++;

                });

                var tempSurveyAnswer = [];
                var i2 = 1;
                row.original.surveyAnswers.forEach(element => {

                  tempSurveyAnswer.push({
                    number: i2,
                    answer: element 
                  });
                  i2++;

                });
                

                return <Box
                sx={{
                  display: 'grid',
                  margin: 'auto',
                  gridTemplateColumns: '1fr 1fr',
                  width: '100%',
                }}
                >
                  <h1>Profile Answers Table</h1>                  
                  <MaterialReactTable columns={columnsDetail} data={tempProfile} />

                  <h1>Survey Answers Table</h1>
                  <MaterialReactTable columns={columnsDetail} data={tempSurveyAnswer} />
                </Box>
                }
              }}
            />
        </>;
      }

function DeleteToken(tokenId, setTableData){  
    var adminToken = JSON.parse(localStorage.getItem("survey_token"));
    var requestUrl = '/api/public/deleteToken';
    const requestJson = {
      adminToken: adminToken,
      tokenToDelete: tokenId
   };

   var orderDetails = new OrderDetails(requestUrl, requestJson, false, "POST");
   APIHeadQuarters.ExecuteStandardOrder(orderDetails).then((res)=>{      
    
      if(res.errorCode){
        return;
      }      


      Swal.fire({
        confirmButtonColor: '#5088c1',
        title: 'Success',
        text: "ลบสำเร็จ",
        icon: 'success',
        confirmButtonText: 'OK'
      })


      LoadAllToken(setTableData); 
     });  
}

function LoadAllToken(setTableData){
  var token = JSON.parse(localStorage.getItem("survey_token"));
  var requestUrl = '/api/public/allTokenInfo';
  const requestJson = {
    token: token
 };

 var orderDetails = new OrderDetails(requestUrl, requestJson, false, "POST");
 APIHeadQuarters.ExecuteStandardOrder(orderDetails).then((res)=>{      
  
    if(res.errorCode){
      return;
    }

    if(res){
      res.forEach(row => {
        if(row.tokenType == 1){
          row.tokenTypeName = "ลูกทีม";
        }else if(row.tokenType == 2){
          row.tokenTypeName = "หัวหน้าทีม";
        }
        
      });
    }

    setTableData(res);
    
   });
}

export default TokensInfoTable;
