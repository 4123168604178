import React, { useState } from 'react';
import './index.scss';
import HrSurveyCard from '../../../share-component/hr';
import SurveyGroupBox from '../survey-groupbox';
import { motion } from "framer-motion"
import TitleSurvey from "../../../share-component/header/titleSurvey"

function SurveyCard3({onNumberAnswered, scoresReadOnly, isVisibleLeft, isVisibleRight}) {  

  const initial = {scale: 0.9, opacity: 0.9};
  const whileInView = {scale: 1, opacity: 1};
  const animate = {};
  const whileHover = { scale: 1.007 };
  
  return (
    <>
        <motion.div animate={animate}
                    whileHover={whileHover}
                    initial={initial}
                    whileInView={whileInView}                    
                    >
          <TitleSurvey number={3} title={"Psychological Safety Climate"} isVisibleLeft={isVisibleLeft} isVisibleRight={isVisibleRight}/>
        </motion.div>
        
        <motion.div animate={animate}
                    whileHover={whileHover}
                    initial={initial}
                    whileInView={whileInView}                    
                    >
          <div className='survey-card-container'>
              <SurveyQuestion questionNumber={1} scoresReadOnly={scoresReadOnly} realQuestionNumber={14} question={"เมื่อฉันทำผิดพลาดตอนอยู่ในทีมนี้ ส่วนมากฉันมักจะเป็นผู้ถูกกล่าวโทษ"} onNumberAnswered={onNumberAnswered} />
              <hr className='hr-survey-card'/>
              <SurveyQuestion questionNumber={2} scoresReadOnly={scoresReadOnly} realQuestionNumber={15} question={"สมาชิกในทีมนี้มักนำปัญหาและประเด็นที่น่าหนักใจมาให้เพื่อนร่วมงานในทีม"} onNumberAnswered={onNumberAnswered} />
          </div>
        </motion.div>

        <hr className='hr-survey-card'/>

        <motion.div animate={animate}
                    whileHover={whileHover}
                    initial={initial}
                    whileInView={whileInView}
                      >
          <div className='survey-card-container'>
              <SurveyQuestion questionNumber={3} scoresReadOnly={scoresReadOnly} realQuestionNumber={16} question={"บางครั้งสมาชิกในทีมก็เลือกที่จะไม่ยอมรับผู้อื่นที่แตกต่าง"} onNumberAnswered={onNumberAnswered} />
              <hr className='hr-survey-card'/>
              <SurveyQuestion questionNumber={4} scoresReadOnly={scoresReadOnly} realQuestionNumber={17} question={"ฉันรู้สึกปลอดภัยถ้าจะต้องรับความเสี่ยงร่วมกับคนในทีม"} onNumberAnswered={onNumberAnswered} />
          </div>
        </motion.div>

        <hr className='hr-survey-card'/>

        <motion.div animate={animate}
                    whileHover={whileHover}
                    initial={initial}
                    whileInView={whileInView}
                    >
          <div className='survey-card-container'>
              <SurveyQuestion questionNumber={5} scoresReadOnly={scoresReadOnly} realQuestionNumber={18} question={"มันยากที่จะขอความช่วยเหลือจากสมาชิกคนอื่นในทีม"} onNumberAnswered={onNumberAnswered} />
              <hr className='hr-survey-card'/>
              <SurveyQuestion questionNumber={6} scoresReadOnly={scoresReadOnly} realQuestionNumber={19} question={"ในทีมนี้ ไม่มีใครเลยที่ตั้งใจจะบั่นทอนความพยายามของฉัน"} onNumberAnswered={onNumberAnswered} />
          </div>
        </motion.div>

        <hr className='hr-survey-card'/>

        <motion.div animate={animate}
                    whileHover={whileHover}
                    initial={initial}
                    whileInView={whileInView}
                    >
          <div className='survey-card-container'>
              <SurveyQuestion questionNumber={7} scoresReadOnly={scoresReadOnly} realQuestionNumber={20} question={"การทำงานในทีมนี้ ทำให้ความสามารถและพรสวรรค์ของฉันมีคุณค่าและมีประโยชน์"} onNumberAnswered={onNumberAnswered} />             
          </div>
        </motion.div>
        
    </>
  );
}

function SurveyCardContainer(){

}

function SurveyQuestion({ questionNumber, question, onNumberAnswered, realQuestionNumber, scoresReadOnly}){
  
  var valueInit = 0;
  var isSelectInit = false;
  
  if(scoresReadOnly){
    var thisQuestionScore = scoresReadOnly[realQuestionNumber-1];
    if(thisQuestionScore && thisQuestionScore > 0){
      isSelectInit = true;
      valueInit = thisQuestionScore;
    }
  }

  return (
    <>
      <motion.div  
      initial={{ opacity: 0, scale: 0 }}
      animate={{ opacity: 1, scale: 1 }}
      exit={{ opacity: 0 }} >
        <h1 className='is-size-4-tablet is-size-6-mobile mb-6'> {questionNumber}. {question}</h1>
        <SurveyGroupBox onNumberAnswered={onNumberAnswered} scoresReadOnly={scoresReadOnly} realQuestionNumber={realQuestionNumber} valueInit={valueInit} isSelectInit={isSelectInit}/>
      </motion.div>
    </>
  )
}


export default SurveyCard3;