import React, { useState, useEffect } from 'react';
import "./index.css";
import { LoginService } from '../api/services/LoginService'
import { useNavigate, Link } from "react-router-dom";

function NavbarSurveyProgress() {  
  const [isActive, setisActive] = React.useState(false); 
  const navigate = useNavigate();
  useEffect(() => {        
    LoginService.IsLogin(navigate, "/");
  });


  return (
    <>
      <div className='stack-item-container'>
        <div className='stack-item bg-1'></div>
        <div className='stack-item bg-2'></div>
        <div className='stack-item bg-3'></div>
        <div className='stack-item bg-4'></div>
        <div className='stack-item bg-5'></div>
      </div>
    </>
  );
}

export default NavbarSurveyProgress;