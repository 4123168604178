import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import { useLocation } from 'react-router-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

import './index.scss'
import {
  createBrowserRouter,
  RouterProvider,
  Route,
} from "react-router-dom";
import Login from './pages/login'
import SurveyPart1 from './pages/survey/survey-part1'
import SurveyPart2 from './pages/survey/survey-part2'
import SurveyPart3 from './pages/survey/survey-part3'
import SurveyPart4 from './pages/survey/survey-part4';
import SurveyPart5 from './pages/survey/survey-part5';
import SurveyPart6 from './pages/survey/survey-part6';
import SurveyPart7 from './pages/survey/survey-part7';
import SurveyPart8 from './pages/survey/survey-part8';
import SurveyPart9 from './pages/survey/survey-part9';
import SurveyPart10 from './pages/survey/survey-part10';
import SurveyConsent from './pages/survey/survey-consent';
import LoginAdmin from './pages/loginAdmin';
import AdminMenu from './pages/surveyAnswer/AdminMenu';
import AddToken from './pages/surveyAnswer/AddToken';
import TokenInfo from './pages/surveyAnswer/TokenInfo';
import AddTokenInfo from './pages/surveyAnswer/AddTokenInfo';
import AllTokenInfo from './pages/surveyAnswer/AllTokenInfo';

import SurveyBossConsent from './pages/surveyBoss/survey-boss-consent';
import SurveyBossPart1 from './pages/surveyBoss/survey-part1'
import SurveyBossPart2 from './pages/surveyBoss/survey-part2'
import SurveyBossPart3 from './pages/surveyBoss/survey-part3'
import SurveyBossPart4 from './pages/surveyBoss/survey-part4'
import SurveyBossPart5 from './pages/surveyBoss/survey-part5'
import SurveyBossPart6 from './pages/surveyBoss/survey-part6'
import SurveyBossPart7 from './pages/surveyBoss/survey-part7'
import 'bulma/css/bulma.min.css'

function useScrollToTop() {
  const { pathname } = useLocation();  
  
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

}

const router = createBrowserRouter([
  {
    path: "/",
    element: <Login/>,
  },
  {
    path: "/survey/consent",
    element: <SurveyConsent/>,
  },
  {
    path: "/survey/part1",
    element: <SurveyPart1/>,
  },
  {
    path: "/survey/part2",
    element: <SurveyPart2/>,
  },  {
    path: "/survey/part3",
    element: <SurveyPart3/>,
  },
  {
    path: "/survey/part4",
    element: <SurveyPart4/>,
  },
  {
    path: "/survey/part5",
    element: <SurveyPart5/>,
  },
  {
    path: "/survey/part6",
    element: <SurveyPart6/>,
  },
  {
    path: "/survey/part7",
    element: <SurveyPart7/>,
  },
  {
    path: "/survey/part8",
    element: <SurveyPart8/>,
  },
  {
    path: "/survey/part9",
    element: <SurveyPart9/>,
  },
  {
    path: "/survey/part10",
    element: <SurveyPart10/>,
  },
  {
    path: "/loginAdmin",
    element: <LoginAdmin/>
  },
  {
    path: "/AdminMenu",
    element: <AdminMenu/>
  },
  {
    path: "/AddToken",
    element: <AddToken/>
  },
  {
    path: "/TokenInfo",
    element: <TokenInfo/>
  },
  {
    path: "/AllTokenInfo",
    element: <AllTokenInfo/>
  },
  {
    path: "/AddTokenInfo",
    element: <AddTokenInfo/>
  },
  {
    path: "/survey/typeL/part1",
    element: <SurveyBossPart1/>,
  },
  {
    path: "/survey/typeL/part2",
    element: <SurveyBossPart2/>,
  },  
  {
    path: "/survey/typeL/part3",
    element: <SurveyBossPart3/>,
  },
  {
    path: "/survey/typeL/part4",
    element: <SurveyBossPart4/>,
  },
  {
    path: "/survey/typeL/part5",
    element: <SurveyBossPart5/>,
  } ,
  {
    path: "/survey/typeL/part6",
    element: <SurveyBossPart6/>,
  } ,
  {
    path: "/survey/typeL/part7",
    element: <SurveyBossPart7/>,
  },
  {
    path: "/survey/typeL/consent",
    element: <SurveyBossConsent/>,
  }
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>     
     <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
