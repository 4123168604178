import React, { useState } from 'react';
import NavBar from '../../../share-component/navbar'
import { Form, Icon, Button } from 'react-bulma-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGraduationCap } from '@fortawesome/free-solid-svg-icons'
import { Link} from "react-router-dom";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Swal from 'sweetalert2'
import {APIHeadQuarters} from '../../../share-component/api/APIHeadQuarters';
import {OrderDetails} from '../../../share-component/api/OrderDetails';
import { faKey } from '@fortawesome/free-solid-svg-icons'

function AddToken() {    
  
  return (
    <React.Fragment>
    
      <NavBar></NavBar>      
      <div className="columns is-centered has-text-left mt-2 mr-2 ml-2">        
        <Link to={'/AdminMenu'}> <p className='is-pulled-left is-size-7'>Back</p></Link>
        <div className="column is-one-quarter">
          <FormAddToken></FormAddToken>
        </div>   
      </div>
    
    </React.Fragment>
  );
}

function FormAddToken(){
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = React.useState(false);
  const [tokenType, setTokenType] = React.useState('1');
  const refCompanyName = React.useRef(null);
  const refGroup = React.useRef(null);
  const refToken = React.useRef(null);  



  const onSubmit = (e) => {
    e.preventDefault();
    
    if(refCompanyName.current.value == ""){
      alert("Company name is required")
      return;
    }
    if(refGroup.current.value == ""){
      alert("Group is required")
      return;
    }
    if(refToken.current.value == ""){
      alert("Token is required")
      return;
    }
    if(tokenType == ""){
      alert("Token Type is required")
      return;
    }
    
  
    setIsLoading(true);
    
    var requestUrl = '/api/public/addToken';
    const requestJson = {
      companyName: refCompanyName.current.value,
      group: refGroup.current.value,
      token: refToken.current.value,
      tokenType: parseInt(tokenType)
   };

   var orderDetails = new OrderDetails(requestUrl, requestJson, false, "POST");
   APIHeadQuarters.ExecuteStandardOrder(orderDetails).then((res)=>{
      setIsLoading(false);
    
      if(res.errorCode){
        return;
      }

      Swal.fire({
        confirmButtonColor: '#5088c1',
        title: 'Success',
        text: "เพิ่มสำเร็จ",
        icon: 'success',
        confirmButtonText: 'OK'
      })
      
      refToken.current.value = "";
      // navigate("/adminMenu");
   });
   
    
  };

   return ( 
    <>      
      <h1 className="is-size-2 mb-5 has-text-weight-bold has-text-centered">Add Token</h1>
      <form>
        <Form.Field>
          <Form.Label>Company Name</Form.Label>
          <Form.Control>
            <Form.Input domRef={refCompanyName} placeholder="Company Name" name="companyName"/>
          </Form.Control>
        </Form.Field>        

        <Form.Field>
          <Form.Label>Group</Form.Label>
          <Form.Control>
            <Form.Input domRef={refGroup} placeholder="Group" name="Group" />
          </Form.Control>
        </Form.Field>

        <Form.Field>
          <Form.Label>Token</Form.Label>
          <Form.Control>
            <Form.Input domRef={refToken} placeholder="Token" name="token" />
          </Form.Control>
        </Form.Field>        

        <Form.Field>
          <Form.Label>Token Type</Form.Label>
          <Form.Control>
            <Form.Select
              onChange={function noRefCheck(e){                
                setTokenType(e.target.value)
              }}
              value={tokenType}>
              <option value="1">
                ลูกทีม
              </option>
              <option value="2">
                หัวหน้า
              </option>
            </Form.Select>
          </Form.Control>
        </Form.Field>

        <Form.Control>          
          <Button fullwidth className={`mt-4 ${isLoading ? 'is-loading' : ''}`} color="black" onClick={(e)=>onSubmit(e)}>Add</Button>
        </Form.Control>      
      </form>
      
      {/* <button class="button is-normal is-black is-fullwidth mt-4">Login</button> */}
    </>
    );
}


export default AddToken;
