import React, { useState, useEffect } from 'react';
import { motion } from "framer-motion"
import './index.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck } from '@fortawesome/free-solid-svg-icons'
import StorageManager from '../../../share-component/storage/storageManager';

function SurveyRadio({onNumberAnswered, realQuestionNumber, valueInit=0, isSelectInit=false}) {
  const [value, setValue] = React.useState(valueInit);
  const [isSelect, setIsSelect] = React.useState(isSelectInit);
  const [color, setColor] = React.useState("");



  useEffect(() => {        

  });

  useEffect(() => {            
    StorageManager.HandleSaveAnswer(value, realQuestionNumber);
  }, [value]);
  
  const onClicked = (number)=>{    
    calculateColor(number);
  };

  const calculateColor = (number)=>{
    setValue(number);
    if(isSelect === false){ 
      setIsSelect(true); 
      onNumberAnswered();
    }

    switch (number) {
      case 1:
        setColor("is-danger is-completed");
        break;
      case 2:
        setColor("is-warning is-completed");
        break;  
      case 3:
        setColor("is-dark is-completed");
        break;
      case 4:
        setColor("is-info is-completed");
        break; 
      case 5:
        setColor("is-success is-completed");
        break;  
      default:
        break;
      
    }
  }

  return (
    <>
      <div className="columns is-centered">                
        <div className="column">
        <ul className="steps is-medium">
          <Step number={1} text1="ไม่เห็นด้วย" text2="มากที่สุด" isSelect={isSelect} value={value} color={color} setColor={setColor} onClicked={onClicked}/>          
          <Step number={2} text1="ไม่เห็นด้วย" text2="" isSelect={isSelect} value={value} color={color} setColor={setColor} onClicked={onClicked}/>
          <Step number={3} text1="ปานกลาง" text2=""  isSelect={isSelect} value={value} color={color} setColor={setColor} onClicked={onClicked}/>
          <Step number={4} text1="เห็นด้วย" text2="" isSelect={isSelect} value={value} color={color} setColor={setColor} onClicked={onClicked}/>
          <Step number={5} text1="เห็นด้วย" text2="มากที่สุด" isSelect={isSelect} value={value} color={color} setColor={setColor} onClicked={onClicked}/>            
        </ul>
        </div>
      </div>     
    </>
  );
}
function Step({onClicked, isSelect, setValue, setIsSelect, setColor, number, value, text1, text2, color}){  
  const [color2, setColor2] = React.useState("is-gray");

  const onLinkClick = (number) =>{        
    onClicked(number);
  }
  

  useEffect(() => {                
    if(value !== number){            
      setColor2('is-gray');
    }else{
      setColor2('');
    }
  }, [value, number]);

  return(
    <>
      <li className={`step-item ${color}`}>
        <motion.div                         
                      onTap={(e)=>{
                        onLinkClick(number);
                      }}>        
          <a>
          <div className={"step-marker " + color2} >
            {color2 === '' ? <FontAwesomeIcon icon={faCheck} /> : ''}  
          </div>
          </a>
        </motion.div>
        <SurveyScaleDetail number={number} text1={text1} text2={text2} onClicked={onLinkClick}
          isSelect={isSelect} value={value}/>            
      </li>
    </>
  )
}



function SurveyScaleDetail({ number, text1, text2, onClicked, isSelect, value }){
  const onLinkClick = (e) =>{
    e?.preventDefault();
    onClicked(number);
  }

  useEffect(() => {                
    if(value == number){            
      onLinkClick();
    }
  });

  return (
    <>
      <div className="step-details is-primary is-completed" >
        <a className='black' onClick={onLinkClick}>
            <motion.div animate={{fontSize: (value == number ) ? '1.5rem' : '1rem'}}
                      whileTap={{scale: 0.5}}
                      onTap={(e)=>{
                        onLinkClick(e);
                      }}>        
              <div className='step-title is-size-8-mobile'>{number}</div>
            </motion.div>
            <div className='is-size-8-mobile is-size-7-desktop'> 
                <div onClick={onLinkClick}>{text1}</div> 
                <div onClick={onLinkClick}>{text2}</div>
            </div>
            
        </a>
      </div>
    </>
  );
}

export default SurveyRadio;