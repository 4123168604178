import React, { useState, useEffect } from 'react';
import NavBar from '../../../share-component/navbar'
import ButtonCard from '../../../share-component/button-card'
import './index.scss'
import NavBarnavbarSurveyProgress from '../../../share-component/navbar-survey-progress'
import { motion } from "framer-motion"
import { LoginService } from '../../../share-component/api/services/LoginService'
import { useNavigate } from "react-router-dom";

function SurveyConsent() {    
  const initial = {scale: 0.9, opacity: 0.9};
  const whileInView = {scale: 1, opacity: 1};
  const animate = {};
  const whileHover = { scale: 1.007 };
  const navigate = useNavigate();
  useEffect(() => {        
    // LoginService.IsLogin(navigate);
  });

  useEffect(() => {        
    window.scrollTo(0, 0);
  });

  return (
    <>
      <NavBar/>
      <NavBarnavbarSurveyProgress/>      
      <motion.div animate={animate}
                    whileHover={whileHover}
                    initial={initial}
                    whileInView={whileInView}                    
                    >
        <div className='survey-card-container has-text-centered'>
            <h1 className='is-size-4 is-size-6-mobile'></h1>
            <b><span className='is-size-3 is-size-4-mobile'> Survey Consent</span></b>
        </div>      
      </motion.div>
      <ConsentCard />
      
    </>
  ); 
}

function ConsentCard() {
  const [nextUrl, setNextUrl] = React.useState("/survey/part1");

  useEffect(() => {             
    var tokenModel = JSON.parse(localStorage.getItem("token_model"));    
    if(tokenModel != undefined && tokenModel.tokenType == 2){
      setNextUrl("/survey/typeL/part1");
    }  
  });


  return (
    <>
      <div className='survey-card-container'>
        <div className='consent-body-container'>
          <ConsentBody/>
        </div>


        <ButtonCard className="mt-3" text='ยอมรับข้อตกลง' total={1} numberAnswered={1} isSubmit={false} nextPageURL={nextUrl}/>
      </div> 
          
    </>
  ); 
}


function ConsentBody() {

  return (
    <>
      {/* <div class="has-text-centered">
          <figure class="image is-128x128 is-inline-block">
              <img class="is-rounded" src="https://resources.phdresearch2022.com/nida-logo.jpg"/>
          </figure>
      </div>
      <div class="has-text-centered">
        <h1 className='is-size-5'>
          <b>เอกสารช้ีแจงผู้เข้าร่วมการวิจัย</b>
        </h1>
        <h1>
          <b>สำหรับสมาชิกในทีม</b>
        </h1>
      </div> */}
      <div>
        {/* <iframe className='consent-pdf' src="https://resources.phdresearch2022.com/consent-for-team.pdf"/> */}
        <img className='image consent-pdf'  src='https://resources.phdresearch2022.com/consent-for-team-1.jpg'></img>
        <img className='image consent-pdf' src='https://resources.phdresearch2022.com/consent-for-team-2.jpg'></img>
        <img className='image consent-pdf' src='https://resources.phdresearch2022.com/consent-for-team-3.jpg'></img>
        <img className='image consent-pdf' src='https://resources.phdresearch2022.com/consent-for-team-4.jpg'></img>
        {/* <iframe id="pdfviewer" src="http://docs.google.com/gview?embedded=true&url=https://resources.phdresearch2022.com/consent-for-team.pdf&amp;embedded=true" frameborder="0" width="100%" height="100%"></iframe> */}
        {/* <iframe className='consent-pdf' src="https://docs.google.com/gview?embedded=true&url=https://resources.phdresearch2022.com/consent-for-team.pdf&amp;embedded=true"/> */}
        {/* <iframe className='consent-pdf' src="https://resources.phdresearch2022.com/consent-for-team.pdf"/> */}
      </div>

      {/* <AllPagesPDFViewer pdf={"https://resources.phdresearch2022.com/consent-for-team.pdf"} ></AllPagesPDFViewer> */}
 
    </>
  )
}

export default SurveyConsent;