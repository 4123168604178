import axios from "axios";
import Swal from 'sweetalert2'
import {APIHeadQuarters} from '../APIHeadQuarters';
import {OrderDetails} from '../OrderDetails';
import { useNavigate } from "react-router-dom";

export class LoginService {    
  
    static async IsLogin(navigate, redirectPath = "/"){    
                
        if(localStorage.getItem("survey_token") == null){
            navigate(redirectPath)
            return;
        }
        
        var token =  JSON.parse(localStorage.getItem("survey_token"));        

        var requestUrl = '/api/public/isLogin';
        const requestJson = {
            token: token
        };

        var orderDetails = new OrderDetails(requestUrl, requestJson, false, "POST");
        APIHeadQuarters.ExecuteStandardOrder(orderDetails).then((res)=>{            
            
            if(res.errorCode){
                navigate(redirectPath);
                return;
            }
        });    
    }
}
