import React, { useState, useEffect } from 'react';
import NavBar from '../../../share-component/navbar'
import { Form, Icon, Button } from 'react-bulma-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGraduationCap } from '@fortawesome/free-solid-svg-icons'
import { Link} from "react-router-dom";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import Swal from 'sweetalert2'
import {APIHeadQuarters} from '../../../share-component/api/APIHeadQuarters';
import {OrderDetails} from '../../../share-component/api/OrderDetails';
import { faKey } from '@fortawesome/free-solid-svg-icons'

function AddTokenInfo() {    
    
  return (
    <React.Fragment>
    
      <NavBar></NavBar>      
      <div className="columns is-centered has-text-left mt-2 mr-2 ml-2">        
        <Link to={'/AdminMenu'}> <p className='is-pulled-left is-size-7'>Back</p></Link>
        <div className="column is-one-quarter">
          <FormAddToken></FormAddToken>
        </div>   
      </div>
    
    </React.Fragment>
  );
}

function FormAddToken(){
  var tokenModel = JSON.parse(localStorage.getItem("token_model"));  

  const navigate = useNavigate();
  const [isLoading, setIsLoading] = React.useState(false);  
  const [companyName, setCompanyName] = React.useState(tokenModel.companyName);
  const [group, setGroup] = React.useState(tokenModel.group);
  const [token, setToken] = React.useState(tokenModel.token);  
  const [tokenType, setTokenType] = React.useState(tokenModel.tokenType);  

  const [nextUrl, setNextUrl] = React.useState("/survey/part1");

  useEffect(() => {             
    var tokenModel = JSON.parse(localStorage.getItem("token_model"));    
    if(tokenModel.tokenType == 2){
      setNextUrl("/survey/typeL/part1");
    }  
  });

  const onSubmit = (e) => {
    e.preventDefault();
    
   
    navigate(nextUrl);      
    
  };

   return ( 
    <>      
      <h1 className="is-size-2 mb-5 has-text-weight-bold has-text-centered">Add Token</h1>
      <form>
        <Form.Field>
          <Form.Label>Company Name</Form.Label>
          <Form.Control>
            <Form.Input defaultValue={companyName} placeholder="Company Name" name="companyName"/>
          </Form.Control>
        </Form.Field>        

        <Form.Field>
          <Form.Label>Group</Form.Label>
          <Form.Control>
            <Form.Input defaultValue={group} placeholder="Group" name="Group" />
          </Form.Control>
        </Form.Field>

        <Form.Field>
          <Form.Label>Token</Form.Label>
          <Form.Control>
            <Form.Input defaultValue={token} placeholder="Token" name="token" />
          </Form.Control>
        </Form.Field>        

        <Form.Field>
          <Form.Label>Token Type</Form.Label>
          <Form.Control>
            <Form.Select defaultValue={tokenType}>
              <option value="1">
                ลูกทีม
              </option>
              <option value="2">
                หัวหน้า
              </option>
            </Form.Select>
          </Form.Control>
        </Form.Field>

        <Form.Control>          
          <Button fullwidth className={`mt-4 ${isLoading ? 'is-loading' : ''}`} color="black" onClick={(e)=>onSubmit(e)}>ดูผล Survey</Button>
        </Form.Control>      
      </form>
      
      {/* <button class="button is-normal is-black is-fullwidth mt-4">Login</button> */}
    </>
    );
}


export default AddTokenInfo;
