import axios from "axios";
import Swal from 'sweetalert2'

export class APIHeadQuarters {    
  
    static async ExecuteStandardOrder(orderDetails){

        if(!orderDetails.requestUrl){            
            return
        }
        if(orderDetails.hasLoading){            
        }

        var res = null;

        try{

            if(orderDetails.requestMethod == "POST"){
                res = await axios.post(orderDetails.fullRequestUrl, orderDetails.requestJson);
            }else if(orderDetails.requestMethod == "GET"){
                res = await axios.get(orderDetails.fullRequestUrl);
            }

            

        }catch(error){             
            res = {};
            res.data = {
                errorCode :"X99",
                errorMessage : error
            }            
        }

        console.log(res);

        if(res.data.errorCode && !orderDetails.handleErrorYourSelf){
            this.HandleError(res.data, orderDetails.showError, orderDetails.goToLoginIfSessionTimeOut);            
        }

        return res.data;

    }
    


    static async HandleError(data, isShowError, goToLoginIfSessionTimeOut){        
        var errorMessage = data.errorMessage;
        if(isShowError)
        {                 
            Swal.fire({
                confirmButtonColor: '#5088c1',
                title: '',
                text: errorMessage,
                icon: 'error',
                confirmButtonText: 'OK'
              })                               
        }
    }
  
}
