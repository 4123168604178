import React, { useState, useEffect } from 'react';
import './index.scss';
import NavBarnavbarSurveyProgress from '../../../share-component/navbar-survey-progress'
import SurveyBossCard1 from '../survey-boss-card1'
import SurveyProgress from '../../survey/survey-progress'
import ButtonCard from '../../../share-component/button-card'

function SurveySection1({sectionNumber}) {    
  const [numberAnswered, setNumberAnswered] = React.useState(0);
    const [isFirstTime, setIsFirstTime] = React.useState(true);
  const [isVisibleLeft, setIsVisibleLeft] = React.useState(false);
  const [isVisibleRight, setIsVisibleRight] = React.useState(false);
   
  const totalNumber = 6;
  const startRealQuestionIndex = 0;
  const endRealQuestionIndex = 5;
  const onNumberAnswered = (e) => {    
    setNumberAnswered(numberAnswered + 1);
  };
  
  var profileScores = JSON.parse(localStorage.getItem("profile_scores"));

  console.log("test")

  useEffect(() => {             
    if(isFirstTime){
      setIsFirstTime(false)
      if(profileScores != undefined){
        var numberAnwser = 0;
        for (let i = startRealQuestionIndex; i <= endRealQuestionIndex; i++) {
          const score = profileScores[i];
          if(score > 0){
            numberAnwser++;
          }      
        }                 
                setNumberAnswered(numberAnwser);

        if(profileScores.length > endRealQuestionIndex){
          setIsVisibleRight(true)
        }
      }
    }       
  });

  

  return (
    <>
      <NavBarnavbarSurveyProgress/>
      <SurveyBossCard1 onNumberAnswered={onNumberAnswered} profileScores={profileScores} isVisibleLeft={isVisibleLeft} isVisibleRight={isVisibleRight}/>
      <ButtonCard total={totalNumber} numberAnswered={numberAnswered} isSubmit={false} nextPageURL={"/survey/typeL/part2"}/>
      <SurveyProgress numberAnswered={numberAnswered} total={totalNumber} />      
  
    </>
  );
}

export default SurveySection1;