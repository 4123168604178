import React, { useState } from 'react';
import NavBar from '../../../share-component/navbar'
import { Form, Icon, Button } from 'react-bulma-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGraduationCap } from '@fortawesome/free-solid-svg-icons'
import { Link} from "react-router-dom";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Swal from 'sweetalert2'
import {APIHeadQuarters} from '../../../share-component/api/APIHeadQuarters';
import {OrderDetails} from '../../../share-component/api/OrderDetails';
import { faKey } from '@fortawesome/free-solid-svg-icons'

function TokenInfo() {    
  
  return (
    <React.Fragment>
    
      <NavBar></NavBar>
      <div className="columns is-centered has-text-left mt-2 mr-2 ml-2">
        <Link to={'/AdminMenu'}> <p className='is-pulled-left is-size-7'>Back</p></Link>
        <div className="column is-one-quarter">
          <FormAddToken></FormAddToken>
        </div>   
      </div>
    
    </React.Fragment>
  );
}

function FormAddToken(){
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = React.useState(false);
  const [token, setToken] = React.useState('');

  const onSubmit = (e) => {
    e.preventDefault();

    setIsLoading(true);
    
    var requestUrl = '/api/public/getTokenInfo';
    const requestJson = {
      token: token
   };

   var orderDetails = new OrderDetails(requestUrl, requestJson, false, "POST");
   APIHeadQuarters.ExecuteStandardOrder(orderDetails).then((res)=>{
      setIsLoading(false);
    
      if(res.errorCode){
        return;
      }

      localStorage.setItem("profile_scores", JSON.stringify(res.profileAnswers));
      localStorage.setItem("survey_scores", JSON.stringify(res.surveyAnswers));
      localStorage.setItem("is_token_info_mode", JSON.stringify(true));
      localStorage.setItem("token_model", JSON.stringify(res));

      navigate("/AddTokenInfo");
   });
   
    
  };

   return ( 
    <>
      <h1 className="is-size-2 mb-5 has-text-weight-bold has-text-centered">Token Info</h1>
      <form>
        <Form.Field>
          <Form.Label>Token</Form.Label>
          <Form.Control>
            <Form.Input placeholder="Token" name="name" value={token} onChange={event=>{              
              setToken(event.target.value);
            }}/>
          </Form.Control>
        </Form.Field>        
        <Form.Control>          
          <Button fullwidth className={`mt-4 ${isLoading ? 'is-loading' : ''}`} color="black" onClick={(e)=>onSubmit(e)}>Info</Button>
        </Form.Control>      
      </form>
      
      {/* <button class="button is-normal is-black is-fullwidth mt-4">Login</button> */}
    </>
    );
}


export default TokenInfo;
