import React, { useState, useRef, useEffect, forwardRef } from 'react';
import { motion } from "framer-motion"
import './index.scss';
import StorageManager from '../../../share-component/storage/storageManager';

function SurveyGroupBox({onNumberAnswered, realQuestionNumber, valueInit=0, isSelectInit=false}) {    
  const [value, setValue] = React.useState(valueInit);
  const [isSelect, setIsSelect] = React.useState(isSelectInit);  
  const ref = useRef(null);  

  useEffect(() => {        

    StorageManager.HandleSaveAnswer(value, realQuestionNumber);    

  }, [value]);
  

  useEffect(() => {        
        
    if(isSelect == true){      
      
      onNumberAnswered();
    }
  }, [isSelect]);

  return (
    <>
      
      <div className="buttons has-addons columns is-centered btn-group-container">
        <BtnInGroup text={1} onNumberAnswered={onNumberAnswered} setIsSelect={setIsSelect} color="is-danger" value={value} setValue={setValue}/>
        <BtnInGroup text={2} onNumberAnswered={onNumberAnswered} setIsSelect={setIsSelect} color="is-warning" value={value} setValue={setValue}/>
        <BtnInGroup text={3} onNumberAnswered={onNumberAnswered} setIsSelect={setIsSelect} color="is-orange" value={value} setValue={setValue}/>
        <BtnInGroup text={4} onNumberAnswered={onNumberAnswered} setIsSelect={setIsSelect} color="is-info" value={value} setValue={setValue}/>
        <BtnInGroup text={5} onNumberAnswered={onNumberAnswered} setIsSelect={setIsSelect} color="is-success" value={value} setValue={setValue}/>
      </div>
      <SurveyScale isSelect={isSelect} value={value} setIsSelect={setIsSelect} onClicked={(number)=>{
        setValue(number);        
      }}/>
    </>
  );
}

function BtnInGroup({text, onNumberAnswered, value, setValue, setIsSelect, color}){
    const ref = useRef(null);  

    useEffect(() => {        
      if(value != text){        
        if(ref.current.className.includes('is-selected')){ 
          ref.current.className = ref.current.className.replace(` ${color} is-selected`, "");          
        }
      }else if(value == text){        
        if(!ref.current.className.includes('is-selected')){ 
          ref.current.className += ` ${color} is-selected`;
          setIsSelect(true);          
        }
      }
    }, [value]);

    return (
      <>
        <button ref={ref} className="button column is-one-fifth btn-in-group is-size-4 is-size-6-mobile" onClick={(e)=>{
          
          setValue(text);
          
        }}>{text}</button>
      </>
    )
}

function GroupBox(){

}

function SurveyScale({onClicked, isSelect, value}){
  return (
    <>
      <div className="columns is-mobile has-text-centered">
        <SurveyScaleDetail number={1} text1="ไม่เห็นด้วย" text2="มากที่สุด" onClicked={onClicked} isSelect={isSelect} value={value}/>
        <SurveyScaleDetail number={2} text1="ไม่เห็นด้วย" text2="" onClicked={onClicked} isSelect={isSelect} value={value}/>
        <SurveyScaleDetail number={3} text1="ปานกลาง" text2="" onClicked={onClicked}  isSelect={isSelect} value={value}/>
        <SurveyScaleDetail number={4} text1="เห็นด้วย" text2="" onClicked={onClicked} isSelect={isSelect} value={value}/>
        <SurveyScaleDetail number={5} text1="เห็นด้วย" text2="มากที่สุด" onClicked={onClicked} isSelect={isSelect} value={value}/>              
      </div>
    </>
  )
}

function SurveyScaleDetail({ number, text1, text2, onClicked, isSelect, value }){
  const onLinkClick = (e) =>{
    e.preventDefault(); 
    onClicked(number);    
  }

  

  return (
    <>
      <div className="column is-one-fifth is-size-7-mobile scale-detail-container" >
        <a className='black' onClick={onLinkClick}>
            <motion.div animate={{fontSize: (isSelect) ? '1.5rem' : '1rem'}}
                      whileTap={{scale: 0.5}}
                      onTap={(e)=>{
                        onLinkClick(e);
                      }}>        
              <div className='is-size-8-mobile is-size-7-desktop'> 
                  <div className='black'>{text1}</div> 
                  <div className='black'>{text2}</div>
              </div>
            </motion.div>                        
        </a>
      </div>
    </>
  );
}

export default SurveyGroupBox;