export class OrderDetails {



    constructor(
                url = '',
                requestJson = null,
                hasLoading = false,
                requestMethod = 'POST',
                showError = true,
                handleErrorYourSelf = false,
                goToLoginIfSessionTimeOut = false) {        
        this.requestUrl = url;
        this.requestJson = requestJson;
        this.hasLoading = hasLoading;
        this.requestMethod = requestMethod;
        this.showError = showError;
        this.handleErrorYourSelf = handleErrorYourSelf;
        this.goToLoginIfSessionTimeOut = goToLoginIfSessionTimeOut;

        const apiUrl = process.env.NODE_ENV === 'production' ? 'https://6rjfjn9uxk.execute-api.ap-southeast-1.amazonaws.com/surveyPaniAPI' : 'http://localhost:8081';
        this.baseURL = apiUrl;
        this.fullRequestUrl = this.baseURL + this.requestUrl;
    }
}

export const OrderPostType = {
    POST: 'POST',
    GET: 'GET'
}