import React, { useState, useEffect } from 'react';
import NavBar from '../../../share-component/navbar'
import { Form, Icon, Button } from 'react-bulma-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGraduationCap } from '@fortawesome/free-solid-svg-icons'
import { Link} from "react-router-dom";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Swal from 'sweetalert2'
import {APIHeadQuarters} from '../../../share-component/api/APIHeadQuarters';
import {OrderDetails} from '../../../share-component/api/OrderDetails';
import { faKey } from '@fortawesome/free-solid-svg-icons'
import TokensInfoTable  from "./TokensInfoTable"

function AllTokenInfo() {  
  
  const [tableData, setTableData] = React.useState([{token: '1234'}]);
  
  return (
    <React.Fragment>    
      <NavBar></NavBar>
      <div className="columns is-centered has-text-left mt-2 mr-2 ml-2">
        <Link to={'/AdminMenu'}> <p className='is-pulled-left is-size-7'>Back</p></Link>
        <div className="column is-four-fifths">
          <FormAddToken></FormAddToken>
        </div>
      </div>
    
    </React.Fragment>
  );
}

function FormAddToken(){
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = React.useState(false);
  const [token, setToken] = React.useState('');

   return ( 
    <>
      <h1 className="is-size-2 mb-5 has-text-weight-bold has-text-centered">All Tokens Info</h1>

      <TokensInfoTable></TokensInfoTable>
            
    </>
    );
}


export default AllTokenInfo;
