import React, { useState } from 'react';
import './index.scss';
import HrSurveyCard from '../../../share-component/hr';
import SurveyRadio from '../survey-radio';
import { motion } from "framer-motion"
import TitleSurvey from "../../../share-component/header/titleSurvey"

function SurveyCard4({onNumberAnswered, scoresReadOnly, isVisibleLeft, isVisibleRight}) {  
  const initial = {scale: 0.9, opacity: 0.9};
  const whileInView = {scale: 1, opacity: 1};
  const animate = {};
  const whileHover = { scale: 1.007 };
  
  return (
    <>
        <motion.div animate={animate}
                    whileHover={whileHover}
                    initial={initial}
                    whileInView={whileInView}                    
                    >
          <TitleSurvey number={4} title={"Perceived Group Inclusion"} isVisibleLeft={isVisibleLeft} isVisibleRight={isVisibleRight}/>          
        </motion.div>
        <motion.div animate={animate}
                    whileHover={whileHover}
                    initial={initial}
                    whileInView={whileInView}                    
                    >
          <div className='survey-card-container'>
              <SurveyQuestion questionNumber={1} realQuestionNumber={21} scoresReadOnly={scoresReadOnly} question={"ทีมนี้ทำให้ฉันรู้สึกว่า นี่คือที่ของฉัน"} onNumberAnswered={onNumberAnswered} />
              <hr className='hr-survey-card'/>
              <SurveyQuestion questionNumber={2} realQuestionNumber={22} scoresReadOnly={scoresReadOnly} question={"ทีมนี้ทำให้ฉันรู้สึกว่า ฉันคือส่วนหนึ่งของทีม"} onNumberAnswered={onNumberAnswered} />
          </div>
        </motion.div>

        <hr className='hr-survey-card'/>

        <motion.div animate={animate}
                    whileHover={whileHover}
                    initial={initial}
                    whileInView={whileInView}
                      >
          <div className='survey-card-container'>
              <SurveyQuestion questionNumber={3} realQuestionNumber={23} scoresReadOnly={scoresReadOnly} question={"ทีมนี้ทำให้ฉันรู้สึกว่า ฉันเหมาะกับทีมนี้"} onNumberAnswered={onNumberAnswered} />
              <hr className='hr-survey-card'/>
              <SurveyQuestion questionNumber={4} realQuestionNumber={24} scoresReadOnly={scoresReadOnly} question={"ทีมนี้ปฏิบัติกับฉันเหมือนเป็นสมาชิกที่สำคัญในทีม"} onNumberAnswered={onNumberAnswered} />
          </div>
        </motion.div>

        <hr className='hr-survey-card'/>

        <motion.div animate={animate}
                    whileHover={whileHover}
                    initial={initial}
                    whileInView={whileInView}
                    >
          <div className='survey-card-container'>
              <SurveyQuestion questionNumber={5} realQuestionNumber={25} scoresReadOnly={scoresReadOnly} question={"สมาชิกในทีมนี้ชอบฉัน"} onNumberAnswered={onNumberAnswered} />
              <hr className='hr-survey-card'/>
              <SurveyQuestion questionNumber={6} realQuestionNumber={26} scoresReadOnly={scoresReadOnly} question={"สมาชิกในทีมนี้เห็นคุณค่าของฉัน"} onNumberAnswered={onNumberAnswered} />
          </div>
        </motion.div>

        <hr className='hr-survey-card'/>

        <motion.div animate={animate}
                    whileHover={whileHover}
                    initial={initial}
                    whileInView={whileInView}
                    >
          <div className='survey-card-container'>
              <SurveyQuestion questionNumber={7} realQuestionNumber={27} scoresReadOnly={scoresReadOnly} question={"สมาชิกในทีมยินดีที่มีฉันร่วมทีม"} onNumberAnswered={onNumberAnswered} />
              <hr className='hr-survey-card'/>
              <SurveyQuestion questionNumber={8} realQuestionNumber={28} scoresReadOnly={scoresReadOnly} question={"สมาชิกในทีมนี้ห่วงใยฉัน"} onNumberAnswered={onNumberAnswered} />
          </div>
        </motion.div>

        <hr className='hr-survey-card'/>

        <motion.div animate={animate}
                    whileHover={whileHover}
                    initial={initial}
                    whileInView={whileInView}
                    >
          <div className='survey-card-container'>
              <SurveyQuestion questionNumber={9} realQuestionNumber={29} scoresReadOnly={scoresReadOnly} question={'ทีมนี้ทำให้ฉันเป็นฉันอย่างที่ฉันเป็น'} onNumberAnswered={onNumberAnswered} />
              <hr className='hr-survey-card'/>
              <SurveyQuestion questionNumber={10} realQuestionNumber={30} scoresReadOnly={scoresReadOnly} question={"ทีมนี้ทำให้ฉันเป็นตัวของตัวเอง"} onNumberAnswered={onNumberAnswered} />
          </div>
        </motion.div>

        <hr className='hr-survey-card'/>

        <motion.div animate={animate}
                    whileHover={whileHover}
                    initial={initial}
                    whileInView={whileInView}
                    >
          <div className='survey-card-container'>
              <SurveyQuestion questionNumber={11} realQuestionNumber={31} scoresReadOnly={scoresReadOnly} question={'ทีมนี้ทำให้ฉันสามารถแสดงถึงตัวตนที่แท้จริงของฉันได้'} onNumberAnswered={onNumberAnswered} />
              <hr className='hr-survey-card'/>
              <SurveyQuestion questionNumber={12} realQuestionNumber={32} scoresReadOnly={scoresReadOnly} question={"ทีมนี้ทำให้ฉันแสดงออกในแบบที่เป็นตัวเอง"} onNumberAnswered={onNumberAnswered} />
          </div>
        </motion.div>

        <hr className='hr-survey-card'/>

        <motion.div animate={animate}
                    whileHover={whileHover}
                    initial={initial}
                    whileInView={whileInView}
                    >
          <div className='survey-card-container'>
              <SurveyQuestion questionNumber={13} realQuestionNumber={33} scoresReadOnly={scoresReadOnly} question={'ทีมนี้ส่งเสริมให้ฉันเป็นฉันอย่างที่ฉันเป็น'} onNumberAnswered={onNumberAnswered} />
              <hr className='hr-survey-card'/>
              <SurveyQuestion questionNumber={14} realQuestionNumber={34} scoresReadOnly={scoresReadOnly} question={"ทีมนี้ส่งเสริมให้ฉันเป็นตัวของตัวเอง"} onNumberAnswered={onNumberAnswered} />
          </div>
        </motion.div>

        <hr className='hr-survey-card'/>

        <motion.div animate={animate}
                    whileHover={whileHover}
                    initial={initial}
                    whileInView={whileInView}
                    >
          <div className='survey-card-container'>
              <SurveyQuestion questionNumber={15} realQuestionNumber={35} scoresReadOnly={scoresReadOnly} question={'ทีมนี้ส่งเสริมให้ฉันสามารถแสดงถึงตัวตนที่แท้จริงของฉันได้'} onNumberAnswered={onNumberAnswered} />
              <hr className='hr-survey-card'/>
              <SurveyQuestion questionNumber={16} realQuestionNumber={36} scoresReadOnly={scoresReadOnly} question={"ทีมนี้ส่งเสริมให้ฉันแสดงออกในแบบที่เป็นตัวเอง"} onNumberAnswered={onNumberAnswered} />
          </div>
        </motion.div>        
    </>
  );
}

function SurveyCardContainer(){

}

function SurveyQuestion({ questionNumber, question, onNumberAnswered, realQuestionNumber, scoresReadOnly}){
  
  var valueInit = 0;
  var isSelectInit = false;
  
  if(scoresReadOnly){
    var thisQuestionScore = scoresReadOnly[realQuestionNumber-1];
    if(thisQuestionScore && thisQuestionScore > 0){
      isSelectInit = true;
      valueInit = thisQuestionScore;
    }
  }

  return (
    <>
      <motion.div  
      initial={{ opacity: 0, scale: 0 }}
      animate={{ opacity: 1, scale: 1 }}
      exit={{ opacity: 0 }} >
        <h1 className='is-size-4-tablet is-size-6-mobile mb-6'> {questionNumber}. {question}</h1>
        <SurveyRadio onNumberAnswered={onNumberAnswered} realQuestionNumber={realQuestionNumber} valueInit={valueInit} isSelectInit={isSelectInit}/>
      </motion.div>
    </>
  )
}


export default SurveyCard4;