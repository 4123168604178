import React, { useState, useEffect } from 'react';
import NavBar from '../../share-component/navbar'
import SurveySection3 from './survey-section3'


function SurveyPart3() {    

  useEffect(() => {        
    window.scrollTo(0, 0);
  });

  return (
    <>
      <NavBar/>
      <SurveySection3/>
      
      
    </>
  );
}

export default SurveyPart3;