import axios from "axios";
import Swal from 'sweetalert2'

export default class StorageManager {    
  
    static async HandleSaveAnswer(value, realQuestionNumber){

        var scoresReadOnly = JSON.parse(localStorage.getItem("survey_scores"));
    
        if(!scoresReadOnly){
        scoresReadOnly = [];
        }    
        var scores = [...scoresReadOnly];    
        
        if(value != undefined && value != "0"){
        scores[realQuestionNumber-1] = value.toString();
        localStorage.setItem("survey_scores", JSON.stringify(scores));
        }

    }


    static async HandleSaveProfileAnswer(value, realQuestionNumber){

        var profileReadOnly = JSON.parse(localStorage.getItem("profile_scores"));
    
        if(!profileReadOnly){
        profileReadOnly = [];
        }    
        var scores = [...profileReadOnly];    
        
        if(value != undefined && value != "0"){
        scores[realQuestionNumber-1] = value.toString();
        localStorage.setItem("profile_scores", JSON.stringify(scores));
        }

    }
    
  
}
