import React, { useState, useEffect } from 'react';
import logo from '../../logo.svg';
import NavBar from '../../share-component/navbar'
import NavBarnavbarSurveyProgress from '../../share-component/navbar-survey-progress'
import SurveyCard from './survey-card2'
import SurveyProgress from './survey-progress'
import SurveySection5 from './survey-section5'


function SurveyPart5() {    
  useEffect(() => {        
    window.scrollTo(0, 0);
  });

  return (
    <>
      <NavBar/>
      <SurveySection5/>
      
      
    </>
  );
}

export default SurveyPart5;