import React, { useState, useRef, useEffect } from 'react';
import { motion } from "framer-motion"
import './index.scss';
import StorageManager from '../../../share-component/storage/storageManager';

function SurveySlider({onNumberAnswered, realQuestionNumber, valueInit = 0, isSelectInit = false}) {    
  const [value, setValue] = React.useState(valueInit);
  const [isSelect, setIsSelect] = React.useState(isSelectInit);  
  
  useEffect(() => {        

    StorageManager.HandleSaveAnswer(value, realQuestionNumber);

  }, [value]);

  useEffect(() => {        

    if(isSelect){
      onNumberAnswered();
    }

  }, [isSelect]);

  return (
    <>
      <div className="columns is-centered">        
        <input className={`slider column is-12-mobile is-10-desktop is-circle is-medium mb-2 ${isSelect ? 'selected' : ''}`} step="1" min="1" max="5" value={value} type="range" onChange={(event)=>{
          setIsSelect(true);
          setValue(event.target.valueAsNumber);
        }}
        onClick={(e)=>{
          
          setIsSelect(true);
          setValue(e.target.valueAsNumber);
          
        }}></input>
      </div>
      <SurveyScale isSelect={isSelect} value={value} realQuestionNumber={realQuestionNumber} onClicked={(number)=>{
        setIsSelect(true);
        setValue(number);        
        
      }}/>
    </>
  );
}

function SurveyScale({onClicked, isSelect, value, realQuestionNumber}){
  return (
    <>
      <div className="columns is-mobile has-text-centered">
        <SurveyScaleDetail number={1} text1="ไม่เห็นด้วย" text2="มากที่สุด" onClicked={onClicked} isSelect={isSelect} value={value} realQuestionNumber={realQuestionNumber}/>
        <SurveyScaleDetail number={2} text1="ไม่เห็นด้วย" text2="" onClicked={onClicked} isSelect={isSelect} value={value} realQuestionNumber={realQuestionNumber}/>
        <SurveyScaleDetail number={3} text1="ปานกลาง" text2="" onClicked={onClicked}  isSelect={isSelect} value={value} realQuestionNumber={realQuestionNumber}/>
        <SurveyScaleDetail number={4} text1="เห็นด้วย" text2="" onClicked={onClicked} isSelect={isSelect} value={value} realQuestionNumber={realQuestionNumber}/>
        <SurveyScaleDetail number={5} text1="เห็นด้วย" text2="มากที่สุด" onClicked={onClicked} isSelect={isSelect} value={value} realQuestionNumber={realQuestionNumber}/>              
      </div>
    </>
  )
}

function SurveyScaleDetail({ number, text1, text2, onClicked, isSelect, value, realQuestionNumber}){
  const onLinkClick = (e) =>{    
    e?.preventDefault(); 
    onClicked(number);    
  }

  return (
    <>
      <div className="column is-one-fifth is-size-7-mobile scale-detail-container" >
        <a className='black' onClick={onLinkClick}>
            <motion.div animate={{fontSize: (isSelect && value == number ) ? '1.5rem' : '1rem'}}
                      whileTap={{scale: 0.5}}
                      onTap={()=>{
                        onLinkClick();
                      }}>        
              <div>{number}</div>
            </motion.div>
            <div className='is-size-8-mobile is-size-7-desktop'> 
                  <div onClick={onLinkClick}>{text1}</div> 
                  <div onClick={onLinkClick}>{text2}</div>
            </div>
            
        </a>
      </div>
    </>
  );
}

export default SurveySlider;