import React, { useState } from 'react';
import './index.scss';
import HrSurveyCard from '../../../share-component/hr';
import SurveySlider from '../survey-slider';
import { motion } from "framer-motion"
import TitleSurvey from "../../../share-component/header/titleSurvey"

function SurveyCard2({onNumberAnswered, scoresReadOnly, isVisibleLeft, isVisibleRight}) {  
  const initial = {scale: 0.9, opacity: 0.9};
  const whileInView = {scale: 1, opacity: 1};
  const animate = {};
  const whileHover = { scale: 1.007 };
  
  return (
    <>

        
        <motion.div animate={animate}
                    whileHover={whileHover}
                    initial={initial}
                    whileInView={whileInView}                    
                    >
          <TitleSurvey number={2} title={"Spiritual Leadership"} isVisibleLeft={isVisibleLeft} isVisibleRight={isVisibleRight}/>          
        </motion.div>

        <motion.div animate={animate}
                    whileHover={whileHover}
                    initial={initial}
                    whileInView={whileInView}                    
                    >
          <div className='survey-card-container'>
              <SurveyQuestion questionNumber={1} realQuestionNumber={1} scoresReadOnly={scoresReadOnly} question={"หัวหน้าทีมทำให้ฉันเข้าใจและยึดมั่นในวิสัยทัศน์ของทีม"} onNumberAnswered={onNumberAnswered} />
              <hr className='hr-survey-card'/>
              <SurveyQuestion questionNumber={2} realQuestionNumber={2} scoresReadOnly={scoresReadOnly} question={"หัวหน้าทีมของฉันมีวิสัยทัศน์ที่สามารถดึงศักยภาพที่ดีของฉันออกมาได้"} onNumberAnswered={onNumberAnswered} />
          </div>
        </motion.div>

        <hr className='hr-survey-card'/>

        <motion.div animate={animate}
                    whileHover={whileHover}
                    initial={initial}
                    whileInView={whileInView}
                      >
          <div className='survey-card-container'>
              <SurveyQuestion questionNumber={3} realQuestionNumber={3} scoresReadOnly={scoresReadOnly} question={"วิสัยทัศน์ของหัวหน้าทีมผลักดันให้ฉันแสดงศักยภาพที่ดีที่สุดออกมาได้"} onNumberAnswered={onNumberAnswered} />
              <hr className='hr-survey-card'/>
              <SurveyQuestion questionNumber={4} realQuestionNumber={4} scoresReadOnly={scoresReadOnly} question={"วิสัยทัศน์ของหัวหน้าทีมนั้นชัดเจนและมีอิทธิพลต่อฉัน"} onNumberAnswered={onNumberAnswered} />
          </div>
        </motion.div>

        <hr className='hr-survey-card'/>

        <motion.div animate={animate}
                    whileHover={whileHover}
                    initial={initial}
                    whileInView={whileInView}
                    >
          <div className='survey-card-container'>
              <SurveyQuestion questionNumber={5} realQuestionNumber={5} scoresReadOnly={scoresReadOnly} question={"ฉันมีความเชื่อมั่นในตัวหัวหน้าทีม และฉันยินดีทำอะไรก็ตามที่สามารถทำให้ภารกิจนั้นสำเร็จ"} onNumberAnswered={onNumberAnswered} />
              <hr className='hr-survey-card'/>
              <SurveyQuestion questionNumber={6} realQuestionNumber={6} scoresReadOnly={scoresReadOnly} question={"ฉันแสดงออกถึงความเชื่อมั่นในหัวหน้าทีมและภารกิจของทีมโดยการทำทุกสิ่งที่ฉันสามารถทำได้เพื่อให้พวกเราประสบความสำเร็จ"} onNumberAnswered={onNumberAnswered} />
          </div>
        </motion.div>

        <hr className='hr-survey-card'/>

        <motion.div animate={animate}
                    whileHover={whileHover}
                    initial={initial}
                    whileInView={whileInView}
                    >
          <div className='survey-card-container'>
              <SurveyQuestion questionNumber={7} realQuestionNumber={7} scoresReadOnly={scoresReadOnly} question={"ฉันพากเพียรทุ่มเทพยายามเพื่อที่จะช่วยให้ทีมของฉันประสบความสำเร็จ เพราะว่าฉันเชื่อมั่นในจุดยืนของทีม"} onNumberAnswered={onNumberAnswered} />
              <hr className='hr-survey-card'/>
              <SurveyQuestion questionNumber={8} realQuestionNumber={8} scoresReadOnly={scoresReadOnly} question={"ฉันตั้งเป้าหมายการทำงานที่ท้าทายเพราะว่าฉันเชื่อมั่นในหัวหน้าทีม และฉันต้องการให้พวกเราประสบความสำเร็จ"} onNumberAnswered={onNumberAnswered} />
          </div>
        </motion.div>

        <hr className='hr-survey-card'/>

        <motion.div animate={animate}
                    whileHover={whileHover}
                    initial={initial}
                    whileInView={whileInView}
                    >
          <div className='survey-card-container'>
              <SurveyQuestion questionNumber={9} realQuestionNumber={9} scoresReadOnly={scoresReadOnly} question={'หัวหน้าทีมของฉันพูดและทำในสิ่งที่เขา/เธอคิด'} onNumberAnswered={onNumberAnswered} />
              <hr className='hr-survey-card'/>
              <SurveyQuestion questionNumber={10} realQuestionNumber={10} scoresReadOnly={scoresReadOnly} question={"หัวหน้าทีมของฉันเป็นคนซื่อตรงและไม่ชอบสร้างภาพ"} onNumberAnswered={onNumberAnswered} />
          </div>
        </motion.div>

        <hr className='hr-survey-card'/>

        <motion.div animate={animate}
                    whileHover={whileHover}
                    initial={initial}
                    whileInView={whileInView}
                    >
          <div className='survey-card-container'>
              <SurveyQuestion questionNumber={11} realQuestionNumber={11} scoresReadOnly={scoresReadOnly} question={'หัวหน้าทีมของฉันนั้นเชื่อใจได้และซื่อสัตย์ต่อลูกทีมของเขา/เธอ'} onNumberAnswered={onNumberAnswered} />
              <hr className='hr-survey-card'/>
              <SurveyQuestion questionNumber={12} realQuestionNumber={12} scoresReadOnly={scoresReadOnly} question={"หัวหน้าทีมของฉันมีความกล้าหาญที่จะปกป้องคนของเขา/เธอ"} onNumberAnswered={onNumberAnswered} />
          </div>
        </motion.div>

        <hr className='hr-survey-card'/>

        <motion.div animate={animate}
                    whileHover={whileHover}
                    initial={initial}
                    whileInView={whileInView}
                    >
          <div className='survey-card-container'>
              <SurveyQuestion questionNumber={13} realQuestionNumber={13} scoresReadOnly={scoresReadOnly} question={'หัวหน้าทีมของฉันนั้นใจดีและเข้าอกเข้าใจคนในทีม เขา/เธอต้องการที่จะช่วยเหลือเสมอเมื่อคนในทีมประสบปัญหา'} onNumberAnswered={onNumberAnswered} />
          </div>
        </motion.div>
        
    </>
  );
}

function SurveyCardContainer(){

}

function SurveyQuestion({ questionNumber, question, onNumberAnswered, realQuestionNumber, scoresReadOnly}){

  var valueInit = 0;
  var isSelectInit = false;
  
  if(scoresReadOnly){
    var thisQuestionScore = scoresReadOnly[realQuestionNumber-1];
    if(thisQuestionScore && thisQuestionScore > 0){
      isSelectInit = true;
      valueInit = thisQuestionScore;      
    }
  }

  return (
    <>
      <motion.div  
      initial={{ opacity: 0, scale: 0 }}
      animate={{ opacity: 1, scale: 1 }}
      exit={{ opacity: 0 }} >
        <h1 className='is-size-4-tablet is-size-6-mobile mb-6'> {questionNumber}. {question}</h1>
        <SurveySlider onNumberAnswered={onNumberAnswered} realQuestionNumber={realQuestionNumber} valueInit={valueInit} isSelectInit={isSelectInit}/>
      </motion.div>
    </>
  )
}


export default SurveyCard2;