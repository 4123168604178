import React, { useState, useEffect } from 'react';
import NavBar from '../../share-component/navbar'
import SurveySection1 from './survey-section1'


function SurveyPart1() {    

  useEffect(() => {        
    window.scrollTo(0, 0);
  });

  return (
    <>
      <NavBar/>
      <SurveySection1/>
      
      
    </>
  );
}

export default SurveyPart1;