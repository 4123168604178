import React, { useState, useEffect } from 'react';
import NavBar from '../../../share-component/navbar'
import ButtonCard from '../../../share-component/button-card'
import './index.scss'
import NavBarnavbarSurveyProgress from '../../../share-component/navbar-survey-progress'
import { motion } from "framer-motion"

function SurveyBossConsent() {    
  const initial = {scale: 0.9, opacity: 0.9};
  const whileInView = {scale: 1, opacity: 1};
  const animate = {};
  const whileHover = { scale: 1.007 };
  
  useEffect(() => {        
    window.scrollTo(0, 0);
  });

  return (
    <>
      <NavBar/>
      <NavBarnavbarSurveyProgress/>
      <motion.div animate={animate}
                    whileHover={whileHover}
                    initial={initial}
                    whileInView={whileInView}                    
                    >
        <div className='survey-card-container has-text-centered'>
            <h1 className='is-size-4 is-size-6-mobile'></h1>
            <b><span className='is-size-3 is-size-4-mobile'> Survey Consent</span></b>
        </div>      
      </motion.div>
      <ConsentCard />
      
    </>
  ); 
}

function ConsentCard() {
  const [nextUrl, setNextUrl] = React.useState("/survey/typeL/part1");

  useEffect(() => {             
    var tokenModel = JSON.parse(localStorage.getItem("token_model"));    
    if(tokenModel != undefined && tokenModel.tokenType == 2){
      setNextUrl("/survey/typeL/part1");
    }  
  });


  return (
    <>
      <div className='survey-card-container'>
        <div className='consent-body-container'>
          <ConsentBody/>
        </div>


        <ButtonCard text='ยอมรับข้อตกลง' total={1} numberAnswered={1} isSubmit={false} nextPageURL={nextUrl}/>
      </div> 
          
    </>
  ); 
}


function ConsentBody() {

  var delayInMilliseconds = 500; // half a second

  setTimeout(function() { 

    var iframe = document.getElementsByTagName('iframe')[0];
    iframe.style.background = 'white';
    iframe.contentWindow.document.body.style.backgroundColor = 'white';

  }, delayInMilliseconds);

  return (
    <>
      {/* <div class="has-text-centered">
          <figure class="image is-128x128 is-inline-block">
              <img class="is-rounded" src="https://resources.phdresearch2022.com/nida-logo.jpg"/>
          </figure>
      </div>
      <div class="has-text-centered">
        <h1 className='is-size-5'>
          <b>เอกสารช้ีแจงผู้เข้าร่วมการวิจัย</b>
        </h1>
        <h1>
          <b>สำหรับหัวหน้าทีม</b>
        </h1>
      </div> */}
      <div>
        {/* <iframe className='consent-pdf' src="https://resources.phdresearch2022.com/consent-for-team-lead.pdf" allowtransparency="true" frameBorder={0} /> */}
        <img className='image consent-pdf'  src='https://resources.phdresearch2022.com/consent-for-team-lead-1.jpg'></img>
        <img className='image consent-pdf' src='https://resources.phdresearch2022.com/consent-for-team-lead-2.jpg'></img>
        <img className='image consent-pdf' src='https://resources.phdresearch2022.com/consent-for-team-lead-3.jpg'></img>
        <img className='image consent-pdf' src='https://resources.phdresearch2022.com/consent-for-team-lead-4.jpg'></img>
      </div>
    </>
  )
}

export default SurveyBossConsent;