import React, { useState, useEffect } from 'react';
import './index.scss';
import NavBarnavbarSurveyProgress from '../../../share-component/navbar-survey-progress'
import SurveyCard5 from '../survey-boss-card5'
import SurveyProgress from '../../survey/survey-progress'
import ButtonCard from '../../../share-component/button-card'

function SurveySection5({sectionNumber}) {    
  const [numberAnswered, setNumberAnswered] = React.useState(0);
    const [isFirstTime, setIsFirstTime] = React.useState(true);
  const [isVisibleLeft, setIsVisibleLeft] = React.useState(true);
  const [isVisibleRight, setIsVisibleRight] = React.useState(false);
  const totalNumber = 15;
  const startRealQuestionIndex = 16;
  const endRealQuestionIndex = 30;
  const onNumberAnswered = (e) => {    
    setNumberAnswered(numberAnswered + 1);
  };
  

  // MOCK
  // var scoresReadOnly2 = ["3","3","2","3","4","3","2","1","1","1","1","1","1"];
  // localStorage.setItem("survey_scores", JSON.stringify(scoresReadOnly2));

  var scoresReadOnly = JSON.parse(localStorage.getItem("survey_scores"));

  useEffect(() => {             
    if(isFirstTime){
      setIsFirstTime(false)
      if(scoresReadOnly != undefined){
        var numberAnwser = 0;
        for (let i = startRealQuestionIndex; i <= endRealQuestionIndex; i++) {
          const score = scoresReadOnly[i];
          if(score > 0){
            numberAnwser++;
          }      
        }      
          
                setNumberAnswered(numberAnwser);

        if(scoresReadOnly.length > endRealQuestionIndex){
          setIsVisibleRight(true)
        }      
      }
    }       
  });

  

  return (
    <>
      <NavBarnavbarSurveyProgress/>
      <SurveyCard5 onNumberAnswered={onNumberAnswered} scoresReadOnly={scoresReadOnly}  isVisibleLeft={isVisibleLeft} isVisibleRight={isVisibleRight} />
      <ButtonCard total={totalNumber} numberAnswered={numberAnswered} isSubmit={false} nextPageURL={"/survey/typeL/part6"}/>
      <SurveyProgress numberAnswered={numberAnswered} total={totalNumber} />      
    </>
  );
}

export default SurveySection5;