import React, { useState, useEffect } from 'react';
import './index.scss';
import NavBarnavbarSurveyProgress from '../../../share-component/navbar-survey-progress'
import SurveyCard7 from '../survey-boss-card7'
import SurveyProgress from '../../survey/survey-progress'
import ButtonCard from '../../../share-component/button-card'

function SurveySection7({sectionNumber}) {    
  const [numberAnswered, setNumberAnswered] = React.useState(0);
    const [isFirstTime, setIsFirstTime] = React.useState(true);
  const [isVisibleLeft, setIsVisibleLeft] = React.useState(true);
  const [isVisibleRight, setIsVisibleRight] = React.useState(false);
  const totalNumber = 5;
  const startRealQuestionIndex = 34;
  const endRealQuestionIndex = 38;
  const onNumberAnswered = (e) => {    
    setNumberAnswered(numberAnswered + 1);
  };
  

  // MOCK
  // var scoresReadOnly2 = ["3","3","2","3","4","3","2","1","1","1","1","1","1"];
  // localStorage.setItem("survey_scores", JSON.stringify(scoresReadOnly2));

  var scoresReadOnly = JSON.parse(localStorage.getItem("survey_scores"));

  useEffect(() => {             
    if(isFirstTime){
      setIsFirstTime(false)
      if(scoresReadOnly != undefined){
        var numberAnwser = 0;
        for (let i = startRealQuestionIndex; i <= endRealQuestionIndex; i++) {
          const score = scoresReadOnly[i];
          if(score > 0){
            numberAnwser++;
          }      
        }      
          
                setNumberAnswered(numberAnwser);

        // if(scoresReadOnly.length > endRealQuestionIndex){
          // setIsVisibleRight(true)
        // }      
      }
    }       
  });

  

  return (
    <>
      <NavBarnavbarSurveyProgress/>
      <SurveyCard7 onNumberAnswered={onNumberAnswered} scoresReadOnly={scoresReadOnly}  isVisibleLeft={isVisibleLeft} isVisibleRight={false} />
      <ButtonCard total={totalNumber} numberAnswered={numberAnswered} isSubmit={true}/>
      <SurveyProgress numberAnswered={numberAnswered} total={totalNumber} />      
    </>
  );
}

export default SurveySection7;