import React, { useState } from 'react';
import { Navbar} from 'react-bulma-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser, faRocket, faGraduationCap } from '@fortawesome/free-solid-svg-icons'
import "./index.css";

function NavBar() {  
  const [isActive, setisActive] = React.useState(false); 

  return (
    <nav className="navbar has-shadow">
      <div className="navbar-brand">
        <a className="navbar-item is-hidden-mobile" href="/">
          {/* <img src="/logo.jpg" className='mr-3 navbar-logo' alt="log"/> */}
          <FontAwesomeIcon className='has-text-centered mr-2' icon={faGraduationCap} size={'1x'} />
          <h1><b>แบบสอบถาม</b></h1>
        </a>

        <a className="navbar-item navbar-item-center is-hidden-tablet" href="/">
          {/* <img src="/logo.jpg" className='mr-3 navbar-logo' alt="log"/> */}
          <FontAwesomeIcon className='has-text-centered mr-2' icon={faGraduationCap} size={'1x'} />
          <h1><b>แบบสอบถาม</b></h1>
        </a>

        <a role="button" className={`navbar-burger ${isActive ? "is-active" : ""}`} aria-label="menu" aria-expanded="false" data-target="navbarBasicExample" onClick={()=>{
          setisActive(!isActive);
          }}>
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
        </a>          
      </div>

      <div id="navbarBasicExample" className={`navbar-menu ${isActive ? "is-active" : ""}`}>
        <div className="navbar-start">
          <div className="navbar-item has-dropdown is-hoverable">
            {/* <a className="navbar-link" href="https://bulma.io/documentation/overview/start/">
              Docs
            </a> */}
            <div className="navbar-dropdown is-boxed">
              <a className="navbar-item" href="/">
                Home
              </a>
              {/* <a className="navbar-item" href="https://bulma.io/documentation/overview/modifiers/">
                Modifiers
              </a>
              <a className="navbar-item" href="https://bulma.io/documentation/columns/basics/">
                Columns
              </a>
              <a className="navbar-item" href="https://bulma.io/documentation/layout/container/">
                Layout
              </a>
              <a className="navbar-item" href="https://bulma.io/documentation/form/general/">
                Form
              </a>
              <hr className="navbar-divider" />
              <a className="navbar-item" href="https://bulma.io/documentation/elements/box/">
                Elements
              </a>
              <a className="navbar-item is-active" href="https://bulma.io/documentation/components/breadcrumb/">
                Components
              </a> */}
            </div>
          </div>
        </div>

        {/* <div className="navbar-end">
          <div className="navbar-item">
            <div className="field is-grouped">
              <p className="control">
                <a className="bd-tw-button button" data-social-network="Twitter" data-social-action="tweet" data-social-target="https://bulma.io" target="_blank" href="https://twitter.com/intent/tweet?text=Bulma: a modern CSS framework based on Flexbox&amp;hashtags=bulmaio&amp;url=https://bulma.io&amp;via=jgthms">
                  
                  <span className="icon">
                    <FontAwesomeIcon icon={faUser} />
                  </span>
                  
                  <span>
                    About
                  </span>
                </a>
              </p>              
            </div>
          </div>
        </div> */}
      </div>

      
    </nav>    
  );
}

export default NavBar;