import React, { useState } from 'react';
import './index.scss';
import HrSurveyCard from '../../../share-component/hr';
import SurveyRadio from '../survey-radio';
import { motion } from "framer-motion"
import TitleSurvey from "../../../share-component/header/titleSurvey"

function SurveyCard7({onNumberAnswered, scoresReadOnly, isVisibleLeft, isVisibleRight}) {  

  const initial = {scale: 0.9, opacity: 0.9};
  const whileInView = {scale: 1, opacity: 1};
  const animate = {};
  const whileHover = { scale: 1.007 };
  
  return (
    <>
        <motion.div animate={animate}
                    whileHover={whileHover}
                    initial={initial}
                    whileInView={whileInView}                    
                    >
          <TitleSurvey number={7} title={"Innovative Work Behavior"} subtitleText={"*แนวคิดนวัตกรรม (innovative idea) อ้างอิงถึง ไอเดียที่แปลกใหม่ หรือแตกต่างจากเดิม"} isVisibleLeft={isVisibleLeft} isVisibleRight={isVisibleRight}/>          
        </motion.div>
        
        <motion.div animate={animate}
                    whileHover={whileHover}
                    initial={initial}
                    whileInView={whileInView}                    
                    >
          <div className='survey-card-container'>
              <SurveyQuestion questionNumber={1} scoresReadOnly={scoresReadOnly} realQuestionNumber={48} question={"ฉันสามารถคิดไอเดียใหม่ๆ สำหรับประเด็นยากๆ"} onNumberAnswered={onNumberAnswered} />
              <hr className='hr-survey-card'/>
              <SurveyQuestion questionNumber={2} scoresReadOnly={scoresReadOnly} realQuestionNumber={49} question={"ฉันสามารถแสวงหาวิธีการ เทคนิค หรือเครื่องมือใหม่ๆในการทำงาน"} onNumberAnswered={onNumberAnswered} />
          </div>
        </motion.div>

        <hr className='hr-survey-card'/>

        <motion.div animate={animate}
                    whileHover={whileHover}
                    initial={initial}
                    whileInView={whileInView}
                      >
          <div className='survey-card-container'>
              <SurveyQuestion questionNumber={3} scoresReadOnly={scoresReadOnly} realQuestionNumber={50} question={"ฉันสามารถคิดวิธีแก้ปัญหาที่ไม่เคยมีมาก่อน"} onNumberAnswered={onNumberAnswered} />
              <hr className='hr-survey-card'/>
              <SurveyQuestion questionNumber={4} scoresReadOnly={scoresReadOnly} realQuestionNumber={51} question={"ฉันสามารถส่งเสริมแนวคิดนวัตกรรม"} onNumberAnswered={onNumberAnswered} />
          </div>
        </motion.div>

        <hr className='hr-survey-card'/>

        <motion.div animate={animate}
                    whileHover={whileHover}
                    initial={initial}
                    whileInView={whileInView}
                    >
          <div className='survey-card-container'>
              <SurveyQuestion questionNumber={5} scoresReadOnly={scoresReadOnly} realQuestionNumber={52} question={"ฉันได้รับความเห็นชอบในแนวคิดนวัตกรรมที่ฉันนำเสนอ"} onNumberAnswered={onNumberAnswered} />
              <hr className='hr-survey-card'/>
              <SurveyQuestion questionNumber={6} scoresReadOnly={scoresReadOnly} realQuestionNumber={53} question={"ฉันทำให้บุคคลสำคัญในองค์กรมีความกระตือรือร้นต่อแนวคิดนวัตกรรม"} onNumberAnswered={onNumberAnswered} />        
          </div>
        </motion.div>

        <hr className='hr-survey-card'/>

        <motion.div animate={animate}
                    whileHover={whileHover}
                    initial={initial}
                    whileInView={whileInView}
                    >
          <div className='survey-card-container'>
              <SurveyQuestion questionNumber={7} scoresReadOnly={scoresReadOnly} realQuestionNumber={54} question={"ฉันสามารถปรับเปลี่ยนแนวคิดนวัตกรรมให้เป็นรูปธรรมขึ้นมาได้"} onNumberAnswered={onNumberAnswered} />
              <hr className='hr-survey-card'/>
              <SurveyQuestion questionNumber={8} scoresReadOnly={scoresReadOnly} realQuestionNumber={55} question={"ฉันสามารถนำแนวคิดนวัตกรรมมาใช้ในสภาพแวดล้อมการทำงานอย่างมีระบบ"} onNumberAnswered={onNumberAnswered} />        
          </div>
        </motion.div>

        <hr className='hr-survey-card'/>

        <motion.div animate={animate}
                    whileHover={whileHover}
                    initial={initial}
                    whileInView={whileInView}
                    >
          <div className='survey-card-container'>
              <SurveyQuestion questionNumber={9} scoresReadOnly={scoresReadOnly} realQuestionNumber={56} question={"ฉันสามารถประเมินประโยชน์ที่ได้จากแนวคิดนวัตกรรม"} onNumberAnswered={onNumberAnswered} />
          </div>
        </motion.div>
    </>
  );
}

function SurveyCardContainer(){

}

function SurveyQuestion({ questionNumber, question, onNumberAnswered, realQuestionNumber, scoresReadOnly}){
  
  var valueInit = 0;
  var isSelectInit = false;
  
  if(scoresReadOnly){
    var thisQuestionScore = scoresReadOnly[realQuestionNumber-1];
    if(thisQuestionScore && thisQuestionScore > 0){
      isSelectInit = true;
      valueInit = thisQuestionScore;
    }
  }

  return (
    <>
      <motion.div  
      initial={{ opacity: 0, scale: 0 }}
      animate={{ opacity: 1, scale: 1 }}
      exit={{ opacity: 0 }} >
        <h1 className='is-size-4-tablet is-size-6-mobile mb-6'> {questionNumber}. {question}</h1>
        <SurveyRadio onNumberAnswered={onNumberAnswered} realQuestionNumber={realQuestionNumber} valueInit={valueInit} isSelectInit={isSelectInit}/>
      </motion.div>
    </>
  )
}


export default SurveyCard7;