import React, { useState, useEffect } from 'react';
import NavBar from '../../share-component/navbar'
import SurveySection4 from './survey-section4'


function SurveyPart4() {    

  useEffect(() => {        
    window.scrollTo(0, 0);
  });


  return (
    <>
      <NavBar/>
      <SurveySection4/>
      
      
    </>
  );
}

export default SurveyPart4;