import React, { useState } from 'react';
import './index.scss';
import HrSurveyCard from '../../../share-component/hr';
import SurveySlider from '../../survey/survey-slider';
import { motion } from "framer-motion"
import TitleSurvey from "../../../share-component/header/titleSurvey"

function SurveyBossCard5({onNumberAnswered, scoresReadOnly, isVisibleLeft, isVisibleRight}) {  
  const initial = {scale: 0.9, opacity: 0.9};
  const whileInView = {scale: 1, opacity: 1};
  const animate = {};
  const whileHover = { scale: 1.007 };
  
  return (
    <>

        
        <motion.div animate={animate}
                    whileHover={whileHover}
                    initial={initial}
                    whileInView={whileInView}                    
                    >
          <TitleSurvey number={5} title={"Mindfulness"} isVisibleLeft={isVisibleLeft} isVisibleRight={isVisibleRight} isBoss={true} /> 
        </motion.div>

        <motion.div animate={animate}
                    whileHover={whileHover}
                    initial={initial}
                    whileInView={whileInView}                    
                    >
          <div className='survey-card-container'>
              <SurveyQuestion questionNumber={1} realQuestionNumber={17} scoresReadOnly={scoresReadOnly} question={"ท่านเป็นคนรู้สึกตัวช้า / มีความรู้สึกช้า"} onNumberAnswered={onNumberAnswered} />
              <hr className='hr-survey-card'/>
              <SurveyQuestion questionNumber={2} realQuestionNumber={18} scoresReadOnly={scoresReadOnly} question={"ท่านมักคิดฟุ้งซ่านจนทำของตกหล่นโดยไม่ทันระวัง"} onNumberAnswered={onNumberAnswered} />
          </div>
        </motion.div>

        <hr className='hr-survey-card'/>

        <motion.div animate={animate}
                    whileHover={whileHover}
                    initial={initial}
                    whileInView={whileInView}
                      >
          <div className='survey-card-container'>
              <SurveyQuestion questionNumber={3} realQuestionNumber={19} scoresReadOnly={scoresReadOnly} question={"ท่านรู้สึกว่าเป็นการยากที่ท่านจะมีสมาธิกับสิ่งที่ท่านทำอยู่ในปัจจุบัน"} onNumberAnswered={onNumberAnswered} />
              <hr className='hr-survey-card'/>
              <SurveyQuestion questionNumber={4} realQuestionNumber={20} scoresReadOnly={scoresReadOnly} question={"ท่านมักเดินไปมาไหนโดยไม่สนใจสิ่งต่างๆรอบตัว"} onNumberAnswered={onNumberAnswered} />
          </div>
        </motion.div>
        
        <hr className='hr-survey-card'/>

        <motion.div animate={animate}
                    whileHover={whileHover}
                    initial={initial}
                    whileInView={whileInView}
                      >
          <div className='survey-card-container'>
              <SurveyQuestion questionNumber={5} realQuestionNumber={21} scoresReadOnly={scoresReadOnly} question={"ท่านไม่ค่อยใส่ใจอาการที่ร่างกายท่านรู้สึกหากอาการนั้นไม่รุนแรง"} onNumberAnswered={onNumberAnswered} />
              <hr className='hr-survey-card'/>
              <SurveyQuestion questionNumber={6} realQuestionNumber={22} scoresReadOnly={scoresReadOnly} question={"ท่านเป็นคนลืมชื่อคนง่ายเพียงแค่ไม่นานที่ท่านได้รู้จักคนๆนั้น"} onNumberAnswered={onNumberAnswered} />
          </div>
        </motion.div>

        <hr className='hr-survey-card'/>

        <motion.div animate={animate}
                    whileHover={whileHover}
                    initial={initial}
                    whileInView={whileInView}
                      >
          <div className='survey-card-container'>
              <SurveyQuestion questionNumber={7} realQuestionNumber={23} scoresReadOnly={scoresReadOnly} question={"ท่านมักจะทำอะไรบางอย่างโดยท่านไม่ค่อยรู้เนื้อรู้ตัว"} onNumberAnswered={onNumberAnswered} />
              <hr className='hr-survey-card'/>
              <SurveyQuestion questionNumber={8} realQuestionNumber={24} scoresReadOnly={scoresReadOnly} question={"ท่านทำอะไรเร็วๆโดยไม่ค่อยใส่ใจรายละเอียด"} onNumberAnswered={onNumberAnswered} />
          </div>
        </motion.div>

        <hr className='hr-survey-card'/>

        <motion.div animate={animate}
                    whileHover={whileHover}
                    initial={initial}
                    whileInView={whileInView}
                      >
          <div className='survey-card-container'>
              <SurveyQuestion questionNumber={9} realQuestionNumber={25} scoresReadOnly={scoresReadOnly} question={"ท่านมักคิดถึงเรื่องอนาคตมากไปจนไม่ใส่ใจสิ่งที่เป็นอยู่ในปัจจุบัน"} onNumberAnswered={onNumberAnswered} />
              <hr className='hr-survey-card'/>
              <SurveyQuestion questionNumber={10} realQuestionNumber={26} scoresReadOnly={scoresReadOnly} question={"บางครั้งท่านทำกิจกรรมบางอย่างโดยที่ท่านเองก็ไม่รู้ตัว"} onNumberAnswered={onNumberAnswered} />
          </div>
        </motion.div>

        <hr className='hr-survey-card'/>

        <motion.div animate={animate}
                    whileHover={whileHover}
                    initial={initial}
                    whileInView={whileInView}
                      >
          <div className='survey-card-container'>
              <SurveyQuestion questionNumber={11} realQuestionNumber={27} scoresReadOnly={scoresReadOnly} question={"ระหว่างที่ท่านทำกิจกรรมบางอย่างท่านมักไม่ใส่ใจฟังเวลาคนอื่นพูด"} onNumberAnswered={onNumberAnswered} />
              <hr className='hr-survey-card'/>
              <SurveyQuestion questionNumber={12} realQuestionNumber={28} scoresReadOnly={scoresReadOnly} question={"บางครั้งท่านไปไหนมาไหนโดยท่านเองก็ไม่รู้ตัวว่าไปที่นั่นทำไม"} onNumberAnswered={onNumberAnswered} />
          </div>
        </motion.div>

        <hr className='hr-survey-card'/>

        <motion.div animate={animate}
                    whileHover={whileHover}
                    initial={initial}
                    whileInView={whileInView}
                      >
          <div className='survey-card-container'>
              <SurveyQuestion questionNumber={13} realQuestionNumber={29} scoresReadOnly={scoresReadOnly} question={"ท่านมักครุ่นคิดกับเรื่องอดีดที่ผ่านไปแล้วและเรื่องอนาคตที่ยังมาไม่ถึง"} onNumberAnswered={onNumberAnswered} />
              <hr className='hr-survey-card'/>
              <SurveyQuestion questionNumber={14} realQuestionNumber={30} scoresReadOnly={scoresReadOnly} question={"ท่านพบว่าบ่อยครั้งท่านทำอะไรๆโดยไม่ค่อยใส่ใจในสิ่งที่ทำนั้น"} onNumberAnswered={onNumberAnswered} />
          </div>
        </motion.div>

        <hr className='hr-survey-card'/>

        <motion.div animate={animate}
                    whileHover={whileHover}
                    initial={initial}
                    whileInView={whileInView}
                      >
          <div className='survey-card-container'>
              <SurveyQuestion questionNumber={15} realQuestionNumber={31} scoresReadOnly={scoresReadOnly} question={"ท่านเพลินกับการกินขนมหรือของขบเคี้ยวโดยไม่ค่อยรู้ตัว"} onNumberAnswered={onNumberAnswered} />
          </div>
        </motion.div>
    </>
  );
}

function SurveyCardContainer(){

}

function SurveyQuestion({ questionNumber, question, onNumberAnswered, realQuestionNumber, scoresReadOnly}){

  var valueInit = 0;
  var isSelectInit = false;
  
  if(scoresReadOnly){
    var thisQuestionScore = scoresReadOnly[realQuestionNumber-1];
    if(thisQuestionScore && thisQuestionScore > 0){
      isSelectInit = true;
      valueInit = thisQuestionScore;      
    }
  }

  return (
    <>
      <motion.div  
      initial={{ opacity: 0, scale: 0 }}
      animate={{ opacity: 1, scale: 1 }}
      exit={{ opacity: 0 }} >
        <h1 className='is-size-4-tablet is-size-6-mobile mb-6'> {questionNumber}. {question}</h1>
        <SurveySlider onNumberAnswered={onNumberAnswered} realQuestionNumber={realQuestionNumber} valueInit={valueInit} isSelectInit={isSelectInit}/>
      </motion.div>
    </>
  )
}


export default SurveyBossCard5;