import React, { useState, useEffect } from 'react';
import './index.scss';
import NavBarnavbarSurveyProgress from '../../../share-component/navbar-survey-progress'
import SurveyCard3 from '../survey-boss-card3'
import SurveyProgress from '../../survey/survey-progress'
import ButtonCard from '../../../share-component/button-card'

function SurveySection3({sectionNumber}) {    
  const [numberAnswered, setNumberAnswered] = React.useState(0);
    const [isFirstTime, setIsFirstTime] = React.useState(true);
  const [isVisibleLeft, setIsVisibleLeft] = React.useState(true);
  const [isVisibleRight, setIsVisibleRight] = React.useState(false);
  const totalNumber = 5;
  const startRealQuestionIndex = 4;
  const endRealQuestionIndex = 8;
  const onNumberAnswered = (e) => {    
    setNumberAnswered(numberAnswered + 1);
  };

  var scoresReadOnly = JSON.parse(localStorage.getItem("survey_scores"));
  var tokenModel = JSON.parse(localStorage.getItem("token_model"));

  useEffect(() => {             
    if(isFirstTime){
      setIsFirstTime(false)
      if(scoresReadOnly != undefined){
        var numberAnwser = 0;
        for (let i = startRealQuestionIndex; i <= endRealQuestionIndex; i++) {
          const score = scoresReadOnly[i];
          if(score > 0){
            numberAnwser++;
          }      
        }      
          
                setNumberAnswered(numberAnwser);

        if(scoresReadOnly.length > endRealQuestionIndex){
          setIsVisibleRight(true)
        }      
      }
    }       
  });
  
  return (
    <>
      <NavBarnavbarSurveyProgress/>
      <SurveyCard3 onNumberAnswered={onNumberAnswered} scoresReadOnly={scoresReadOnly}  isVisibleLeft={isVisibleLeft} isVisibleRight={isVisibleRight} />
      <SurveyProgress numberAnswered={numberAnswered} total={totalNumber} />
      <ButtonCard total={totalNumber} numberAnswered={numberAnswered} isSubmit={false} nextPageURL={"/survey/typeL/part4"}/>
    </>    
  );
}

export default SurveySection3;