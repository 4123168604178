import React, { useState } from 'react';
import './index.scss';
import HrSurveyCard from '../../../share-component/hr';
import SurveyGroupBox from '../survey-groupbox';
import { motion } from "framer-motion"
import TitleSurvey from "../../../share-component/header/titleSurvey"

function SurveyCard6({onNumberAnswered, scoresReadOnly, isVisibleLeft, isVisibleRight}) {  

  const initial = {scale: 0.9, opacity: 0.9};
  const whileInView = {scale: 1, opacity: 1};
  const animate = {};
  const whileHover = { scale: 1.007 };
  
  return (
    <>
        <motion.div animate={animate}
                    whileHover={whileHover}
                    initial={initial}
                    whileInView={whileInView}                    
                    >
          <TitleSurvey number={6} title={"Emotional Exhaustion"} isVisibleLeft={isVisibleLeft} isVisibleRight={isVisibleRight}/>          
        </motion.div>
        
        <motion.div animate={animate}
                    whileHover={whileHover}
                    initial={initial}
                    whileInView={whileInView}                    
                    >
          <div className='survey-card-container'>
              <SurveyQuestion questionNumber={1} scoresReadOnly={scoresReadOnly} realQuestionNumber={43} question={"ฉันรู้สึกหมดพลังใจเพราะงานของฉันเอง"} onNumberAnswered={onNumberAnswered} />
              <hr className='hr-survey-card'/>
              <SurveyQuestion questionNumber={2} scoresReadOnly={scoresReadOnly} realQuestionNumber={44} question={"ฉันรู้สึกหมดเรี่ยวแรงหลังจากเลิกงานในแต่ละวัน"} onNumberAnswered={onNumberAnswered} />
          </div>
        </motion.div>

        <hr className='hr-survey-card'/>

        <motion.div animate={animate}
                    whileHover={whileHover}
                    initial={initial}
                    whileInView={whileInView}
                      >
          <div className='survey-card-container'>
              <SurveyQuestion questionNumber={3} scoresReadOnly={scoresReadOnly} realQuestionNumber={45} question={"ฉันรู้สึกเหนื่อยล้าเมื่อตื่นมาตอนเช้าและต้องไปทำงาน"} onNumberAnswered={onNumberAnswered} />
              <hr className='hr-survey-card'/>
              <SurveyQuestion questionNumber={4} scoresReadOnly={scoresReadOnly} realQuestionNumber={46} question={"การทำงานตลอดทั้งวันมันหนักเกินไปสำหรับฉัน"} onNumberAnswered={onNumberAnswered} />
          </div>
        </motion.div>

        <hr className='hr-survey-card'/>

        <motion.div animate={animate}
                    whileHover={whileHover}
                    initial={initial}
                    whileInView={whileInView}
                    >
          <div className='survey-card-container'>
              <SurveyQuestion questionNumber={5} scoresReadOnly={scoresReadOnly} realQuestionNumber={47} question={"ฉันรู้สึกหมดไฟในการทำงาน"} onNumberAnswered={onNumberAnswered} />
          </div>
        </motion.div>
    </>
  );
}

function SurveyCardContainer(){

}

function SurveyQuestion({ questionNumber, question, onNumberAnswered, realQuestionNumber, scoresReadOnly}){
  
  var valueInit = 0;
  var isSelectInit = false;
  
  if(scoresReadOnly){
    var thisQuestionScore = scoresReadOnly[realQuestionNumber-1];
    if(thisQuestionScore && thisQuestionScore > 0){
      isSelectInit = true;
      valueInit = thisQuestionScore;
    }
  }

  return (
    <>
      <motion.div  
      initial={{ opacity: 0, scale: 0 }}
      animate={{ opacity: 1, scale: 1 }}
      exit={{ opacity: 0 }} >
        <h1 className='is-size-4-tablet is-size-6-mobile mb-6'> {questionNumber}. {question}</h1>
        <SurveyGroupBox onNumberAnswered={onNumberAnswered} scoresReadOnly={scoresReadOnly} realQuestionNumber={realQuestionNumber} valueInit={valueInit} isSelectInit={isSelectInit}/>
      </motion.div>
    </>
  )
}


export default SurveyCard6;