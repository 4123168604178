import React, { useState } from 'react';
import './index.scss';

function SurveyProgress({numberAnswered, total}) {    
  
  var progressPercent = (numberAnswered / total) * 100;  

  return (
    <>
      <div className='progress-card-container'>
        <p className='is-size-6'>{numberAnswered} of {total} answered</p>
        <progress className="progress is-small mt-1" value={progressPercent} max="100">30%</progress>
      </div>  
    </>
  );
}

export default SurveyProgress;