import React, { useState, useEffect } from 'react';
import './index.scss';
import HrSurveyCard from '../../../share-component/hr';
import SurveySlider from '../survey-slider';
import { motion } from "framer-motion"
import TitleSurvey from "../../../share-component/header/titleSurvey"
import StorageManager from '../../../share-component/storage/storageManager';

function SurveyCard1({onNumberAnswered, profileScores, isVisibleLeft, isVisibleRight}) {  
  const initial = {scale: 0.9, opacity: 0.9};
  const whileInView = {scale: 1, opacity: 1};
  const animate = {};
  const whileHover = { scale: 1.007 };  

  var isTokenInfoMode = JSON.parse(localStorage.getItem("is_token_info_mode"));
  
  return (
    <>

        
        
        
        <motion.div animate={animate}
                    whileHover={whileHover}
                    initial={initial}
                    whileInView={whileInView}                    
                    >
          <TitleSurvey number={1} title={"ข้อมูลผู้ตอบแบบสอบถาม"} isVisibleLeft={isVisibleLeft} isVisibleRight={isVisibleRight} />          
        </motion.div>

        <motion.div animate={animate}
                    whileHover={whileHover}
                    initial={initial}
                    whileInView={whileInView}                    
                    >
            <Part1 onNumberAnswered={onNumberAnswered} realQuestionNumber={1} profileScores={profileScores} isTokenInfoMode={isTokenInfoMode}/>
          
        </motion.div>

        <motion.div animate={animate}
                    whileHover={whileHover}
                    initial={initial}
                    whileInView={whileInView}                    
                    >

          <Part2 onNumberAnswered={onNumberAnswered} realQuestionNumber={2} profileScores={profileScores} isTokenInfoMode={isTokenInfoMode}/>
          
        </motion.div>

        <motion.div animate={animate}
                    whileHover={whileHover}
                    initial={initial}
                    whileInView={whileInView}                    
                    >
          <Part3 onNumberAnswered={onNumberAnswered} realQuestionNumber={3} profileScores={profileScores} isTokenInfoMode={isTokenInfoMode}/>
          
        </motion.div>


        <motion.div animate={animate}
                    whileHover={whileHover}
                    initial={initial}
                    whileInView={whileInView}                    
                    >
          <Part4 onNumberAnswered={onNumberAnswered} realQuestionNumber={4} profileScores={profileScores} isTokenInfoMode={isTokenInfoMode}/>
          
        </motion.div>


        <motion.div animate={animate}
                    whileHover={whileHover}
                    initial={initial}
                    whileInView={whileInView}                    
                    >
          <Part5 onNumberAnswered={onNumberAnswered} realQuestionNumber={5} profileScores={profileScores} isTokenInfoMode={isTokenInfoMode}/>
          
        </motion.div>

        <motion.div animate={animate}
                    whileHover={whileHover}
                    initial={initial}
                    whileInView={whileInView}                    
                    >

          <Part6 onNumberAnswered={onNumberAnswered} realQuestionNumber={6} profileScores={profileScores} isTokenInfoMode={isTokenInfoMode}/>
          
        </motion.div>



               
    </>
  );
}

function SurveyCardContainer(){

}

function SurveyQuestion({ questionNumber, question, onNumberAnswered, realQuestionNumber, profileReadOnly}){

  var valueInit = 0;
  var isSelectInit = false;
  
  if(profileReadOnly){
    var thisQuestionScore = profileReadOnly[realQuestionNumber-1];
    if(thisQuestionScore && thisQuestionScore > 0){
      isSelectInit = true;
      valueInit = thisQuestionScore;      
    }
  }

  return (
    <>
      <motion.div  
      initial={{ opacity: 0, scale: 0 }}
      animate={{ opacity: 1, scale: 1 }}
      exit={{ opacity: 0 }} >
        <h1 className='is-size-4-tablet is-size-6-mobile mb-6'> {questionNumber}. {question}</h1>
        <SurveySlider onNumberAnswered={onNumberAnswered} realQuestionNumber={realQuestionNumber} valueInit={valueInit} isSelectInit={isSelectInit}/>
      </motion.div>
    </>
  )
}


function Part1({onNumberAnswered, realQuestionNumber, profileScores, isTokenInfoMode}){
    var score = null;
  if(profileScores){
    var myscore = profileScores[realQuestionNumber-1];
    if(myscore){
      score = myscore;
    }
  }

  const [selectedOption, setSelectedOption] = React.useState(score);
  const [isSelect, setIsSelect] = React.useState(false);

  useEffect(() => {       
    if(isSelect){
      onNumberAnswered();
    }         
  }, [isSelect]);

  useEffect(() => {       
    StorageManager.HandleSaveProfileAnswer(selectedOption, realQuestionNumber);
  }, [selectedOption]);

  const onValueChange = (e)=>{
    if(isSelect == false){
      setIsSelect(true);
    }
    setSelectedOption(e.target.value);    
  }

  return (
    <>
        <div className='survey-card-container'>
          <div className="columns is-centered">
            <div className="column is-2-desktop is-offset-1-mobile">
                <label className='form-title mr-4 is-size-5'>1. เพศ</label>
            </div>
            <div className="column is-offset-1-mobile">
              <div className="field">                      
                <input className="is-checkradio is-info"  id="male" type="radio" name="sex" 
                value="1" 
                checked={selectedOption == "1"}
                disabled={isTokenInfoMode == true}
                onChange={onValueChange}
                />
                <label htmlFor="male">ชาย</label>
              </div>
              <div className="field">
                <input className="is-checkradio is-info" id="female" type="radio" name="sex" 
                value="2"
                checked={selectedOption == "2"}
                disabled={isTokenInfoMode == true}
                onChange={onValueChange}                
                />
                <label htmlFor="female">หญิง</label>
              </div>
              <div className="field">
                <input className="is-checkradio is-info" id="none" type="radio" name="sex" 
                value="3"
                checked={selectedOption == "3"}
                disabled={isTokenInfoMode == true}
                onChange={onValueChange}
                />
                <label htmlFor="none">ไม่ต้องการระบุ</label>
              </div>
            </div>
          </div>                                      
        </div>
    </>
  )
}



function Part2({onNumberAnswered, realQuestionNumber, profileScores, isTokenInfoMode}){
    var score = null;
  if(profileScores){
    var myscore = profileScores[realQuestionNumber-1];
    if(myscore){
      score = myscore;
    }
  }

  const [selectedOption, setSelectedOption] = React.useState(score);
  const [isSelect, setIsSelect] = React.useState(false);

  useEffect(() => {       
    if(isSelect){
      onNumberAnswered();
    }         
  }, [isSelect]);

  useEffect(() => {       
    StorageManager.HandleSaveProfileAnswer(selectedOption, realQuestionNumber);
  }, [selectedOption]);

  const onValueChange = (e)=>{
    if(isSelect == false){
      setIsSelect(true);
    }
    setSelectedOption(e.target.value);    
  }

  return (
    <>
        <div className='survey-card-container'>          
          
            <div className="columns">
              <div className="column is-2 is-offset-1-mobile">
                <label className='form-title mr-4 is-size-5'>2. อายุ</label>
              </div>
              <div className="column is-offset-1-mobile">
                <div className="field">                
                  <input className="is-checkradio is-info"  id="age1" type="radio" name="age" 
                          value="1"
                          checked={selectedOption == "1"}
                          disabled={isTokenInfoMode == true}
                          onChange={onValueChange}
                          />
                  <label htmlFor="age1">20-25 ปี</label>
                </div>
                <div className="field">
                  <input className="is-checkradio is-info" id="age2" type="radio" name="age" 
                          value="2"
                          checked={selectedOption == "2"}
                          disabled={isTokenInfoMode == true}
                          onChange={onValueChange}                  
                  />
                  <label htmlFor="age2">26-30 ปี</label>
                </div>
                <div className="field">
                  <input className="is-checkradio is-info" id="age3" type="radio" name="age" 
                          value="3"
                          checked={selectedOption == "3"}
                          disabled={isTokenInfoMode == true}
                          onChange={onValueChange}                  
                  />
                  <label htmlFor="age3">31-35 ปี</label>
                </div>
                <div className="field">
                  <input className="is-checkradio is-info" id="age4" type="radio" name="age" 
                          value="4"
                          checked={selectedOption == "4"}
                          disabled={isTokenInfoMode == true}
                          onChange={onValueChange}                  
                  />
                  <label htmlFor="age4">36-40 ปี</label>
                </div>
                <div className="field">
                  <input className="is-checkradio is-info" id="age5" type="radio" name="age" 
                          value="5"
                          checked={selectedOption == "5"}
                          disabled={isTokenInfoMode == true}
                          onChange={onValueChange}                  
                  />
                  <label htmlFor="age5">41-45 ปี</label>
                </div>
                <div className="field">
                  <input className="is-checkradio is-info" id="age6" type="radio" name="age" 
                          value="6"
                          checked={selectedOption == "6"}
                          disabled={isTokenInfoMode == true}
                          onChange={onValueChange}                  
                  />
                  <label htmlFor="age6">46-50 ปี</label>
                </div>
                <div className="field">
                  <input className="is-checkradio is-info" id="age7" type="radio" name="age" 
                          value="7"
                          checked={selectedOption == "7"}
                          disabled={isTokenInfoMode == true}
                          onChange={onValueChange}
                  />
                  <label htmlFor="age7">51-55 ปี</label>
                </div>
                <div className="field">
                  <input className="is-checkradio is-info" id="age8" type="radio" name="age" 
                          value="8"
                          checked={selectedOption == "8"}
                          disabled={isTokenInfoMode == true}
                          onChange={onValueChange}                  
                  />
                  <label htmlFor="age8">56-60 ปี</label>
                </div>              
              </div>
            </div>                                      
          </div>
    </>
  )
}




function Part3({onNumberAnswered, realQuestionNumber, profileScores, isTokenInfoMode}){
    var score = null;
  if(profileScores){
    var myscore = profileScores[realQuestionNumber-1];
    if(myscore){
      score = myscore;
    }
  }

  const [selectedOption, setSelectedOption] = React.useState(score);
  const [isSelect, setIsSelect] = React.useState(false);

  useEffect(() => {       
    if(isSelect){
      onNumberAnswered();
    }         
  }, [isSelect]);

  useEffect(() => {       
    StorageManager.HandleSaveProfileAnswer(selectedOption, realQuestionNumber);
  }, [selectedOption]);

  const onValueChange = (e)=>{
    if(isSelect == false){
      setIsSelect(true);
    }
    setSelectedOption(e.target.value);    
  }

  return (
    <>
        <div className='survey-card-container'>          
          
          <div className="columns">
            <div className="column is-4 is-offset-1-mobile">
              <label className='form-title mr-4 is-size-5'>3. ระยะเวลาในการทำงานบริษัทนี้</label>
            </div>
            <div className="column is-offset-1-mobile">
             <div className="field">                
                <input className="is-checkradio is-info"  id="month1" type="radio" name="month1" 
                      value="1"
                      checked={selectedOption == "1"}
                      disabled={isTokenInfoMode == true}
                      onChange={onValueChange}
                                          />
                <label htmlFor="month1">น้อยกว่า 1 ปี</label>
              </div>
              <div className="field">                
                <input className="is-checkradio is-info"  id="month2" type="radio" name="month2" 
                      value="2"
                      checked={selectedOption == "2"}
                      disabled={isTokenInfoMode == true}
                      onChange={onValueChange}
                                          />
                <label htmlFor="month2">1-2 ปี</label>
              </div>
              <div className="field">                
                <input className="is-checkradio is-info"  id="month3" type="radio" name="month3" 
                      value="3"
                      checked={selectedOption == "3"}
                      disabled={isTokenInfoMode == true}
                      onChange={onValueChange}
                                          />
                <label htmlFor="month3">3-4 ปี</label>
              </div>
              <div className="field">                
                <input className="is-checkradio is-info"  id="month4" type="radio" name="month4" 
                      value="4"
                      checked={selectedOption == "4"}
                      disabled={isTokenInfoMode == true}
                      onChange={onValueChange}
                                          />
                <label htmlFor="month4">5-6 ปี</label>
              </div>
              <div className="field">
                <input className="is-checkradio is-info" id="month5" type="radio" name="month5" 
                        value="5"
                        checked={selectedOption == "5"}
                        disabled={isTokenInfoMode == true}
                        onChange={onValueChange}                
                />
                <label htmlFor="month5">7-8 ปี</label>
              </div>
              <div className="field">
                <input className="is-checkradio is-info" id="month6" type="radio" name="month6" 
                          value="6"
                          checked={selectedOption == "6"}
                          disabled={isTokenInfoMode == true}
                          onChange={onValueChange}                
                />
                <label htmlFor="month6">9-10 ปี</label>
              </div>
              <div className="field">
                <input className="is-checkradio is-info" id="month7" type="radio" name="month7" 
                          value="7"
                          checked={selectedOption == "7"}
                          disabled={isTokenInfoMode == true}
                          onChange={onValueChange}                
                />
                <label htmlFor="month7">มากกว่า 10 ปี</label>
              </div>
            </div>
          </div>                                      
        </div>
    </>
  )
}



function Part4({onNumberAnswered, realQuestionNumber, profileScores, isTokenInfoMode}){
    var score = null;
  if(profileScores){
    var myscore = profileScores[realQuestionNumber-1];
    if(myscore){
      score = myscore;
    }
  }

  const [selectedOption, setSelectedOption] = React.useState(score);
  const [isSelect, setIsSelect] = React.useState(false);

  useEffect(() => {       
    if(isSelect){
      onNumberAnswered();
    }         
  }, [isSelect]);

  useEffect(() => {       
    StorageManager.HandleSaveProfileAnswer(selectedOption, realQuestionNumber);
  }, [selectedOption]);

  const onValueChange = (e)=>{
    if(isSelect == false){
      setIsSelect(true);
    }
    setSelectedOption(e.target.value);    
  }

  return (
    <>
        <div className='survey-card-container'>          
          
          <div className="columns">
            <div className="column is-4 is-offset-1-mobile">
              <label className='form-title mr-4 is-size-5'>4.	ระยะเวลาในที่เข้าทำงานในทีมในปัจจุบัน</label>
            </div>
            <div className="column is-offset-1-mobile">
              <div className="field">                
                <input className="is-checkradio is-info"  id="work1" type="radio" name="work" 
                          value="1"
                          checked={selectedOption == "1"}
                          onChange={onValueChange}                   
                          disabled={isTokenInfoMode == true}
                />
                <label htmlFor="work1">น้อยกว่า 3 เดือน</label>
              </div>
              <div className="field">
                <input className="is-checkradio is-info" id="work2" type="radio" name="work" 
                          value="2"
                          checked={selectedOption == "2"}
                          onChange={onValueChange}                   
                          disabled={isTokenInfoMode == true}
                />
                <label htmlFor="work2">4-6 เดือน</label>
              </div>
              <div className="field">
                <input className="is-checkradio is-info" id="work3" type="radio" name="work" 
                          value="3"
                          checked={selectedOption == "3"}
                          onChange={onValueChange}                   
                          disabled={isTokenInfoMode == true}
                />
                <label htmlFor="work3">7-12 เดือน</label>
              </div>
              <div className="field">
                <input className="is-checkradio is-info" id="work4" type="radio" name="work" 
                          value="4"
                          checked={selectedOption == "4"}
                          onChange={onValueChange}                   
                          disabled={isTokenInfoMode == true}
                />
                <label htmlFor="work4">มากกว่า 12 เดือน</label>
              </div>
            </div>
          </div>                                      
          </div>
    </>
  )
}



function Part5({onNumberAnswered, realQuestionNumber, profileScores, isTokenInfoMode}){

    var score = null;
  if(profileScores){
    var myscore = profileScores[realQuestionNumber-1];
    if(myscore){
      score = myscore;
    }
  }

  const [selectedOption, setSelectedOption] = React.useState(score);
  const [isSelect, setIsSelect] = React.useState(false);

  useEffect(() => {       
    if(isSelect){
      onNumberAnswered();
    }         
  }, [isSelect]);

  useEffect(() => {       
    StorageManager.HandleSaveProfileAnswer(selectedOption, realQuestionNumber);
  }, [selectedOption]);

  const onValueChange = (e)=>{
    if(isSelect == false){
      setIsSelect(true);
    }
    setSelectedOption(e.target.value);    
  }

  return (
    <>
        <div className='survey-card-container'>          
          
          <div className="columns">
            <div className="column is-4 is-offset-1-mobile">
              <label className='form-title mr-4 is-size-5'>5.	ระดับการศึกษา</label>
            </div>
            <div className="column is-offset-1-mobile">
              <div className="field">                
                <input className="is-checkradio is-info"  id="education1" type="radio" name="education" 
                          value="1"
                          checked={selectedOption == "1"}
                          onChange={onValueChange}                     
                          disabled={isTokenInfoMode == true}
                />
                <label htmlFor="education1">ต่ำกว่าปริญญาตรี</label>
              </div>
              <div className="field">
                <input className="is-checkradio is-info" id="education2" type="radio" name="education" 
                          value="2"
                          checked={selectedOption == "2"}
                          onChange={onValueChange}                     
                          disabled={isTokenInfoMode == true}
                />
                <label htmlFor="education2">ปริญญาตรี</label>
              </div>
              <div className="field">
                <input className="is-checkradio is-info" id="education3" type="radio" name="education" 
                          value="3"
                          checked={selectedOption == "3"}
                          onChange={onValueChange}                     
                          disabled={isTokenInfoMode == true}
                />
                <label htmlFor="education3">ปริญญาโท</label>
              </div>
              <div className="field">
                <input className="is-checkradio is-info" id="education4" type="radio" name="education" 
                          value="4"
                          checked={selectedOption == "4"}
                          onChange={onValueChange}                     
                          disabled={isTokenInfoMode == true}
                />
                <label htmlFor="education4">สูงกว่าปริญญาโท</label>
              </div>
            </div>
          </div>                                      
          </div>
    </>
  )
}



function Part6({onNumberAnswered, realQuestionNumber, profileScores, isTokenInfoMode}){
    var score = null;
  if(profileScores){
    var myscore = profileScores[realQuestionNumber-1];
    if(myscore){
      score = myscore;
    }
  }

  const [selectedOption, setSelectedOption] = React.useState(score);
  const [isSelect, setIsSelect] = React.useState(false);

  useEffect(() => {       
    if(isSelect){
      onNumberAnswered();
    }         
  }, [isSelect]);

  useEffect(() => {       
    StorageManager.HandleSaveProfileAnswer(selectedOption, realQuestionNumber);
  }, [selectedOption]);

  const onValueChange = (e)=>{
    if(isSelect == false){
      setIsSelect(true);
    }
    setSelectedOption(e.target.value);    
  }

  return (
    <>
        <div className='survey-card-container'>          
          
          <div className="columns">
            <div className="column is-4 is-offset-1-mobile">
              <label className='form-title mr-4 is-size-5'>6.	สถานภาพ</label>
            </div>
            <div className="column is-offset-1-mobile">
              <div className="field">                
                <input className="is-checkradio is-info"  id="status1" type="radio" name="status" 
                          value="1"
                          checked={selectedOption == "1"}
                          onChange={onValueChange}                       
                          disabled={isTokenInfoMode == true}
                />
                <label htmlFor="status1">โสด</label>
              </div>
              <div className="field">
                <input className="is-checkradio is-info" id="status2" type="radio" name="status" 
                          value="2"
                          checked={selectedOption == "2"}
                          onChange={onValueChange}                       
                          disabled={isTokenInfoMode == true}
                />
                <label htmlFor="status2">สมรส</label>
              </div>
              <div className="field">
                <input className="is-checkradio is-info" id="status3" type="radio" name="status" 
                          value="3"
                          checked={selectedOption == "3"}
                          onChange={onValueChange}                       
                          disabled={isTokenInfoMode == true}
                />
                <label htmlFor="status3">ไม่ต้องการระบุ</label>
              </div>
            </div>
          </div>                                      
        </div>
    </>
  )
}

export default SurveyCard1;